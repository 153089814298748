import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import PageHeader from '../../common/PageHeader/PageHeader';
import Resources from './Resources';
import WhyJoinUs from './WhyJoinUs';
import StyledButton from '../../common/StyledButton/StyledButton';
import { ReactComponent as CareersGraphic } from '../../media/careers/careers.svg';
import { applicantProLink } from '../../common/Utils/utils';

const blurb = 'We have job openings throughout the year spanning the full spectrum of activities related to software development, cloud computing and cyber security. Additionally, we continue to grow our back office for business development, contract management and human resource activities. Take a look at our open positions and check back for openings in the future.';
    
const buttonLabel = 'Browse Jobs'

const Careers: React.FC = () => {

    return (
        <div className='careers'>
            <PageHeader title='Careers' />
            <div className='career-summary'>
                <Grid className='content' container >
                    <Grid className='summary' item xs={12} md={6} order={{xs: 2, md: 1}}>
                        <Typography className='blurb'>{blurb}</Typography>
                        <StyledButton href={applicantProLink} label={buttonLabel} />
                    </Grid>
                    <Grid className='graphic' item xs={12} md={6} order={{xs: 1, md: 2}}>
                        <CareersGraphic className='svg'/>
                    </Grid>
                </Grid>
            </div>
            <WhyJoinUs/>
            <Resources/>
            <StyledButton href={applicantProLink} label={buttonLabel} />
        </div>
    );
};

export default Careers;