import * as React from 'react';
import { motion } from 'framer-motion';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import PageHeader from '../../common/PageHeader/PageHeader';
import Alliant from './ContractSummaries/Alliant';
import CIOSP3 from './ContractSummaries/CIOSP3';
import GSA8AII from './ContractSummaries/GSA8AII';
import GSA8AIII from './ContractSummaries/GSA8AIII';
import GSAMAS from './ContractSummaries/GSAMAS';
import LogoList from '../../common/LogoList/LogoList';
import { certifications } from '../../common/Utils/utils';

const content = [
    {
        title: 'GSA MAS Schedule (Prime)',
        element: <GSAMAS />
    },{
        title: 'GSA 8(a) STARS III (Joint Venture - Prime)',
        element: <GSA8AIII />
    }, {
        title: 'GSA 8(a) STARS II (Prime)',
        element: <GSA8AII />
    }, {
        title: 'Alliant 2 SB (Subcontract)',
        element: <Alliant />
    }, {
        title: 'CIO-SP 3 (Subcontract)',
        element: <CIOSP3 />
    }
];

const title = 'Contract Vehicles';

/**
 * TODO add sidebar for navigation, take out accordions
 * @returns 
 */
const ContractPage: React.FC = () => {

    const { option } = useParams();
    
    const [expanded, setExpanded] = React.useState<number | undefined>();

    React.useEffect(() => {
        setExpanded(option == 'gsa-8a-stars-iii' ? 1 : 0);
    }, [option]);
    
    const handleClick = (id: number) => {
        if (expanded === id) {
            setExpanded(undefined);
        } else {
            setExpanded(id);
            const anchor = document.getElementById(`contract-${id-1}`);
            if (anchor) anchor.scrollIntoView();
        }
    };

    return (
        <div className='contracts'>
            <PageHeader title={title} />
            <div className='content'>
                {content.map((item, idx) => (
                    <motion.div
                        className='m-div'
                        id={`contract-${item.title}`}
                        key={`contract-${item.title}`}
                        initial={{ opacity: 0 , x: 100}}
                        animate={{ opacity: 1 , x: 0}}
                        transition={{ duration: .8, delay: (idx + 1) * .1 }}
                    >
                        <Accordion className='accordion' expanded={expanded == idx}>
                            <AccordionSummary 
                                id={`link-${idx}`}
                                className='accordion-summary' 
                                expandIcon={expanded === idx
                                    ? <Remove className='arrow'/>
                                    : <Add className='arrow'/>
                                }
                                onClick={() => handleClick(idx)}
                            >
                                <Typography className='summary-text'>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='accordion-details'>{item.element}</AccordionDetails>
                        </Accordion>
                    </motion.div>
                ))}
                <LogoList list={certifications} />
            </div>
        </div>
    );
};

export default ContractPage;