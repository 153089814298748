import * as React from 'react';

import ContentSection from './ContentSection';
import ServiceDisplay from '../ServiceDisplay';
import { ReactComponent as CyberSecurityGraphic }from '../../../media/services/illustrations/cybersecurity.svg';

const CyberSecurity: React.FC = () => {

    const title = 'Cyber Security ';

    const summary = 'SimonComputing has had an ongoing role in cyber security since its inception. Our leaders are experts in authentication, Zero Trust, access control, and the rigorous standards and industry guidelines governing secure processing and implementation. The majority of the applications we build are law enforcement sensitive systems which have stringent security and privacy requirements. We shepherd our systems through rigorous application design reviews, audits and penetration tests to make sure that our systems are secure.';

    const ourExperience = {
        title: 'Our Experience',
        body: 'Security is a critical aspect on all of our projects and we bring many years of end-to-end experience recommending and implementing cybersecurity solutions. We have also helped the government in developing cybersecurity strategies and solutions, identifying and analyzing security risks, threats, and vulnerabilities; developing mitigation solutions; recommending and enforcing security policies; supporting the Certification and Accreditation (C&A) process; supporting and recommending improvements to the Authority to Operate (ATO) process; developing standards and procedures; performing security testing; and developing overall security policies within an agile framework. For example, at DHS CBP our team regularly provided information to senior leaders to facilitate acceptance of risk to CBP operations and assets; the team also helped develop a modern approach for CBP’s Authority to Operate (ATO) accreditation process and provided ISSO support for many information systems.'
    }
    const ourApproach = {
        title: 'Our Approach',
        body: 'We’ve been the early adopters for end-to-end application encryption, various single-sign-on approaches, and advanced auditing techniques.  Security is embedded within all of our agile and DevSecOps processes to ensure it is proactively addressed and designed to industry standards.  We adhere to the Zero Trust framework for securing information in today’s modern digital transformation which helps secure remote workers and various cloud environments.'
    };

    const additonalContent = (
        <div className='additional-content'>
            <ContentSection {...ourExperience} />
            <ContentSection {...ourApproach} />
        </div>
    );

    return (
        <ServiceDisplay 
            serviceId='cyber-security'
            title={title}
            summary={summary}
            graphic={<CyberSecurityGraphic width={450}/>}
            additonalContent={additonalContent}
        />
    );
};

export default CyberSecurity;