import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import AppLayout from './common/AppLayout/AppLayout';
import Services from './features/Services/Services';
import NotFound from './features/NotFound/NotFound';
import Landing from './features/Landing/Landing';
import About from './features/About/About';
import Careers from './features/Careers/Careers';
import Contact from './features/Contact/Contact';
import Contracts from './features/Contracts/Contracts';
import Internship from './features/Internship/Internship';
import News from './features/News/News';
import PartnerWithUs from './features/PartnerWithUs/PartnerWithUs';
import Products from './features/Products/Products';
import TIL from './features/TIL/TIL';
import PostDetails from './features/News/PostDetails';
import Clients from './features/Clients/Clients';

const App: React.FC = () => {

    return (
        <>
            <CssBaseline />
            <AppLayout>
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/partner' element={<PartnerWithUs />} />
                    <Route path='/services/:option?' element={<Services />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/technology-innovations-lab' element={<TIL />} />
                    <Route path='/clients/:id' element={<Clients />} />
                    <Route path='/contracts/:option' element={<Contracts />} />
                    <Route path='/careers' element={<Careers />} />
                    <Route path='/careers/internship' element={<Internship />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/news' element={<News />} />
                    <Route path='/news/:year/:month/:day/:slug' element={<PostDetails />} />
                    <Route path='*' element={<NotFound />} />
                </Routes> 
            </ AppLayout>
        </>
    );
};

export default App;
