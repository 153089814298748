import * as React from 'react';

import ContentSection from './ContentSection';
import ServiceDisplay from '../ServiceDisplay';
import { ReactComponent as CloudMigrationGraphic } from '../../../media/services/illustrations/devsecops.svg';

const CloudMigration: React.FC = () => {

    const title = 'Cloud Migration';
    const summary = 'SimonComputing provides cloud migration for systems, applications and high-volume data sets. Cloud migration is core to digital transformation, providing an on-demand, self-service environment that lowers costs, enhances security and improves agility and reliability.';
    

    const whatWeDeliver = {
        title: 'What We Deliver',
        body: '',
        list: [
            'Efficient migration of high-volume data and applications to the latest cloud environments.',
            'Customized data governance: establishing naming conventions for all objects, fully automating the creation of standard roles and grants, and tagging and masking sensitive data',
            'Cloud-base system in compliance with current government security requirements'
        ]
    };

    const ourExperience = {
        title: 'Our Experience',
        body: 'Our cloud migration services have been critical to carrying the mission of various government agencies.',
        list: [
            ' At DHS Customs and Border Protection (CBP), we led the migration of all applications used across CBP to a cloud-ready or cloud-native state while meeting all security and privacy requirements at DHS.',
            'At DOC Bureau of Industry and Security (BIS), we’ve modernized our approach to data migration by using the Snowflake. Snowflake provides time savings by eliminating administration of hardware while providing on-demand scaling for high performance. We created a standard migration process from Oracle to Snowflake, and automated administration tasks to ensure uniform and error free setup of users, roles, databases, etc.'
        ]
    };

    const additonalContent = (
        <div className='additional-content'>
            <ContentSection {...whatWeDeliver} />
            <ContentSection {...ourExperience} />
        </div>
    );

    return (
        <ServiceDisplay 
            serviceId='agile-devsecops'
            title={title}
            summary={summary}
            graphic={<CloudMigrationGraphic width={450}/>}
            additonalContent={additonalContent}
        />
    );
};

export default CloudMigration;