import * as React from 'react';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { MobileStepper, IconButton, Box } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

/**
 * Information for each image in the carousel
 */
export interface CarouselImage {
    /**
     * Image source
     * @required
     */
    src: string;
    /**
     * Alternative text
     * @required
     */
    alt: string;
}

export interface CarouselProps {
    images: CarouselImage[];
}

/**
 * Carousel will display a set of images as a autoplaying slideshow.
 * It also includes right and left arrows to manually step through images and a stepper
 * to indicate which image is currently being displayed and how many remain.
 * 
 * @param props CarouselProps
 * @returns JSX.Element
 */
const Carousel: React.FC<CarouselProps> = props => {

    const { images } = props;

    const [page, setPage] = React.useState<number>(0);

    // wrap indexes
    const idx = Math.abs(page % images.length);

    const paginate = (newDirection: number) => {
        setPage(prev => prev + newDirection);
    };

    const handleStepChange = (step: number) => {
        setPage(step);
    };

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    return  (
        <div className='carousel'>
            <div className='nav'>
                <div className='box'>
                    <IconButton className='icon-btn' onClick={() => paginate(-1)}><KeyboardArrowLeft fontSize='large'/></IconButton>
                    <Box className='img-div'>
                        <AutoPlaySwipeableViews
                            index={idx}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            axis='x'
                            interval={3000}
                        >
                            {images.map((img, index) => (
                                <Box
                                    key={`img-${index}`}
                                    component='img'
                                    src={img.src}
                                    alt={img.alt}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        overflow: 'hidden',
                                    }}
                                />
                            ))}
                        </AutoPlaySwipeableViews>
                    </Box>
                    <IconButton className='icon-btn' onClick={() => paginate(1)}>
                        <KeyboardArrowRight fontSize='large'/>
                    </IconButton>
                </div>
                <MobileStepper
                    className='stepper'
                    steps={images.length}
                    position='static'
                    activeStep={idx}
                    nextButton={undefined}
                    backButton={undefined}
                />
            </div>
        </div>
    );
};

export default Carousel;