import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { Person as AuthorIcon, CalendarToday as DateIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkToc from 'remark-toc';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import 'highlight.js/styles/github-dark.css';

import { Post } from '../../@types';
import SEO from '../../common/SEO/SEO';
import { createPostUrl, formatDate } from '../../common/Utils/utils';
import StyledButton from '../../common/StyledButton/StyledButton';
import posts from './posts.json';

const title = 'News'
const returnTo = '/news';

const PostDetails: React.FC = () => {

    const { year, month, day, slug } = useParams();
    const navigate = useNavigate();

    const [selectedPost, setSelectedPost] = React.useState<Post>();
    const [prevPost, setPrevPost] = React.useState<string>();
    const [nextPost, setNextPost] = React.useState<string>();
    const [tags, setTags] = React.useState<string[]>([]);

    React.useEffect(() => {
        // get post information if all params specified
        if (year && month && day && slug) {
            const idx = posts.findIndex((p) => (
                year + '-' + month + '-' + day == p.metadata.date.substring(0,10) && 
                slug == p.metadata.slug
            ));

            if (idx != -1) {
                const post = posts[idx];
                setSelectedPost(post);
                setTags(post.metadata.tags.split(' ').map((tag) => tag.charAt(0).toUpperCase() + tag.slice(1)));
    
                const next = idx > 0 && posts[idx-1];
                setNextPost(next
                    ? createPostUrl(next.metadata.date, next.metadata.slug)
                    : undefined
                );
                
                const prev = idx < posts.length - 1 && posts[idx+1];
                setPrevPost(prev
                    ? createPostUrl(prev.metadata.date, prev.metadata.slug)
                    : undefined
                );
                return;
            }
        }

        // else redirect to not found
        navigate('/not-found', { replace: true });
    }, [year, month, day, slug]);

    const getSpacing = () => {
        if (nextPost && prevPost)
            return 'space-between'
        else if (nextPost)
            return 'flex-start'
        else 
            return 'flex-end'
    };

    if (selectedPost == undefined) return (
        <div className='post-details'>
            <CircularProgress />
            <Typography>Loading Post Details</Typography>
        </div>
    );

    return (
        <div className='post-details'>
            <SEO title={title}/>
            <div className='post-container'>
                <Grid container className='post-top-nav' xs={12}>
                    <StyledButton
                        path={returnTo}
                        label={`Back to ${title}`}
                        arrowPlacement='start'
                        variant='text'
                    />
                </Grid>
                <Grid container className='post-content' xs={12} md={10} lg={8}>
                    <Typography className='post-title'>{selectedPost.metadata.title}</Typography>
                    <div className='post-details-div'>
                        <div className='post-detail'>
                            <DateIcon className='icon'/>
                            <Typography>{formatDate(selectedPost.metadata.date)}</Typography>
                        </div>
                        <Divider className='divider'
                            orientation='vertical'
                            variant='middle'
                        />
                        <div className='post-detail'>
                            <AuthorIcon className='icon' />
                            <Typography>{selectedPost.metadata.author}</Typography>
                        </div>
                    </div>
                    {tags.length &&
                        <div className='post-tags'>
                            <Typography className='post-tags-header'>
                                Topic{tags.length > 1 && 's'}:
                            </Typography>
                            <Typography>{tags.join(', ')}</Typography>
                        </div>
                    }
                    <div className='post-hero'>
                        <img src={`/content-images/${selectedPost.metadata.hero}`} alt={selectedPost.metadata.heroAlt}/>
                    </div>
                    <ReactMarkdown className='post-markdown' 
                        rehypePlugins={[ // rehype for html formatting
                            rehypeRaw, // parses html content
                            rehypeHighlight, // syntax highlighting, styling not working out-of-box--linked stylesheet
                            rehypeSlug, // adds id to headers
                        ]}
                        remarkPlugins={[ // remark for markdown formatting
                            remarkToc, // generate table of contents, scroll not working
                            remarkGfm, // support GFM (autolink literals, footnotes, strikethrough, tables, tasklists)
                        ]}
                    >
                        {selectedPost.content}
                    </ReactMarkdown>
                </Grid>
                <Grid className='post-bottom-nav' 
                    container xs={12} 
                    style={{ justifyContent: getSpacing() }}
                >
                    {nextPost &&
                        <StyledButton
                            path={nextPost}
                            label='Newer Post'
                            arrowPlacement='start'
                            variant='text'
                        />
                    }
                    {prevPost && 
                        <StyledButton 
                            path={prevPost} 
                            label='Previous Post' 
                            arrowPlacement='end' 
                            variant='text'
                        />
                    }
                </Grid>
            </div>
        </div>
    );
};

export default PostDetails;