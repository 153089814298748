import * as React from 'react';
import { Box } from '@mui/material';
import StyledButton from '../StyledButton/StyledButton';

interface Props {
    list: {
        img: string;
        alt: string;
        link?: string;
    }[];
    showButton?: boolean; // are the 2 button props redundant
    buttonLabel?: string;
}

const LogoList: React.FC<Props> = (props) => {

    const { list, showButton, buttonLabel } = props;

    return (
        <div className='logo-list'>
            <div className='certs'>
                {list.map((cert) => (
                    <Box className='cert-img'
                        key={`cert-${cert.alt}`}
                        component='img'
                        src={cert.img}
                        alt={cert.alt}
                        onClick={cert.link ? () => window.open(cert.link, '_blank') : undefined}
                        style={cert.link ? { cursor: 'pointer' } : undefined}
                    />
                ))}
            </div>
            {showButton && buttonLabel && 
                <div className='actions'>
                    <StyledButton label={buttonLabel} path='/contact' color='secondary' />
                </div>
            }
        </div>
    );
};

export default LogoList;