import * as React from 'react';

import { Box, Typography } from '@mui/material';

import alliant from '../../../media/contracts/alliant2sb.png'

const Alliant: React.FC = () => {
    return (
        <div className='additional-content'>
            <Box
                className='img'
                component='img'
                src={alliant}
                alt='Alliant 2 SB logo'
            />
            <Typography className='body'>
                Alliant 2, a Best-in-Class Governmentwide Acquisition Contract (GWAC), multiple-award, indefinite delivery, 
                indefinite-quantity (IDIQ) offering complete and flexible IT solutions worldwide.
            </Typography>
        </div>
    );
};

export default Alliant;