import * as React from 'react';
import { Card, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

interface ServiceLinkProps {
    /**
     * Image source
     * @required
     */
    src: string;
    /**
     * Alternative text
     * @required
     */
    alt: string;
    /**
     * Card title
     */
    title: string;
    /**
     * link to navigate to onClick
     */
    link: string;
}

const ServiceCard: React.FC<ServiceLinkProps> = props => {
    const {
        src,
        alt,
        title,
        link,
    } = props;

    const navigate = useNavigate();

    const style: CSSProperties = {
        background: `url(${src}) no-repeat center center`,
    }

    return (
        <Card className='service-card' title={alt} style={style} onClick={() => navigate(link)}>
            <div className='card-content'>
                <Typography className='service-title'>{title}</Typography>
                <div className='card-divider'><div className='dots' /></div>
            </div>
        </Card>
    );
}
export default ServiceCard;