import * as React from 'react';

import ContentSection from './ContentSection';
import ServiceDisplay from '../ServiceDisplay';

import { ReactComponent as DataAnalyticsGraphic } from '../../../media/services/illustrations/data-visualization.svg';

const DataAnalytics: React.FC = () => {

    const title = 'Data Analytics & Visualization';
    const summary = 'We provide comprehensive analytical capabilities, allowing you to use and see data like you never have before.  Our next level of data visualization brings new meaning to our business intelligence and data solutions, using dashboards, 3D graphics, and animation to show you your world - letting you see what is important; providing alerts, trends, and predictions; and giving you a rich set of tools to explore with.  We build dashboard solutions rapidly with our unique toolkit of components - providing you more accuracy, flexibility, and situational awareness than traditional COTS solutions.'

    const whatWeDeliver = {
        title: 'What We Deliver',
        body: 'We successfully deliver many data driven solutions covering the entire spectrum including:',
        list: [
            'Analytics',
            'Business Intelligence',
            'Dashboards and Situational Awareness Tools',
            'Database Design',
            'Data Analysis',
            'Data Migration', 
            'Data Governance',
        ]
    };

    const ourExperience = {
        title: 'Our Experience',
        body: 'SimonComputing has deep experience in the entire range of data solutions including database development, architecture, migration, and strategy.  Our intelligence and visualization capabilities provide rich functionality and the next level of sophistication for making informed decisions.  At the DHS Cybersecurity and Infrastructure Security Agency (CISA) we recently consolidated many diverse data sets into a single solution that provides real-time decision making capabilities for the first time using advanced analytics, dashboards, alerts, and visual displays.'
    };

    const additonalContent = (
        <div className='additional-content'>
            <ContentSection {...whatWeDeliver} />
            <ContentSection {...ourExperience} />
        </div>
    );
    
    return (
        <ServiceDisplay 
            serviceId='data-analytics'
            title={title}
            summary={summary}
            graphic={<DataAnalyticsGraphic width={450}/>}
            // layout='text-right'
            additonalContent={additonalContent}
        />
    );
};

export default DataAnalytics;