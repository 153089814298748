import isoLogo from '../../media/logos/iso.png';
import cmmiLogo from '../../media/logos/cmmi-dev-3.jpeg';
import sbaLogo from '../../media/logos/sba-wosb.png';
import gsaScheduleLogo from '../../media/logos/gsa-mas-schedule.png';
import gsaStarsLogo from '../../media/logos/gsa-8a-stars-3.jpeg'
import dscLogo from '../../media/logos/dsc-logo.png';
import dwosbaLogo from '../../media/logos/dwosba_logo.png';
import snowflakeLogo from '../../media/logos/snowflake_logo.png';
import usdsLogo from '../../media/logos/usds-logo.png'

/**
 * Replace dashes in a date string with forward slashes.
 * 
 * @param dateStr Date string in format `YYYY-MM-DD` or `YYYY/MM/DD`
 * @returns 
 */
const replaceDateStrDash = (dateStr: string) => {
    return dateStr.replace(/-/g, '/');
}

/**
 * Convert date string in format `YYYY-MM-DD` to `Mon DD, YYYY`.
 * Used to format dates on post cards and detail pages.
 * 
 * @param dateStr Date string in format `YYYY-MM-DD` or `YYYY/MM/DD`
 * @returns 
 */
export const formatDate = (dateStr: string) => {
    const dateObj = new Date(replaceDateStrDash(dateStr));
    return dateObj.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
};

/**
 * Create post link with path `/news/<date>/<slug>`
 * 
 * @param dateStr Date string in format `YYYY-MM-DD` or `YYYY/MM/DD`
 * @param slug Unique path to post, must be hyphenated
 * @returns 
 */
export const createPostUrl = (dateStr: string, slug: string) => {
    const formattedDate = replaceDateStrDash(dateStr);
    return '/news/' + formattedDate + '/' + slug;
};

export const certifications = [
    {
        img: sbaLogo,
        alt: 'SBA Certified Woman-Owned Small Business (WOSB)',
    }, {
        img: isoLogo,
        alt: 'ISO 9001 2015 | ISO 20000 1 2018 | ISO/IEC 27001 2013',
    }, {
        img: cmmiLogo,
        alt: 'CMMI Dev 3',
    }, {
        img: gsaScheduleLogo,
        alt: 'GSA MAS Schedule',
    }, {
        img: gsaStarsLogo, 
        alt: 'GSA 8(a) Stars III',
    },
];

export const partners = [
    {
        img: dwosbaLogo,
        alt: 'Digital WOSB Alliance',
        link: 'https://www.digitalwosballiance.org/',
    }, {
        img: dscLogo,
        alt: 'Digital Services Coalition ',
        link: 'https://digitalservicescoalition.org/#/',
    },  {
        img: snowflakeLogo,
        alt: 'Snowflake',
        link: 'https://www.snowflake.com/en/',
    }, {
        img: usdsLogo,
        alt: 'U.S. Digital Service',
        link: 'https://www.usds.gov/'
    }
];

export const applicantProLink = 'https://simoncomputing.applicantpro.com/jobs/';