import * as React from 'react';

import ContentSection from './ContentSection';
import ServiceDisplay from '../ServiceDisplay';

import { ReactComponent as AgileDevGraphic} from '../../../media/services/illustrations/agile-development.svg';
const AgileDevelopment: React.FC = () => {

    const title = 'Agile Development';
    const summary = 'SimonComputing was born from a desire to simplify software development. That led us to utilize Agile development from our inception in 2002. Agile software development has been an integral part of our pursuit to distill our processes to its essence.';
    
    const tools = {
        title: 'Tools',
        body: 'We use a curated suite of open source tools that both private industry and government are converging on including: React, Redux, TypeScript/JavaScript, Material UI, SpringBoot, Java, JPA/Hibernate, PostgreSQL, Oracle, Visual Studio Code and Eclipse.'
    };

    const whatWeDeliver = {
        title: 'What We Deliver',
        body: 'We successfully deliver multiple applications a year, covering a wide range of applications including:',
        list: [
            'Large systems modernizations',
            'Public and private facing web applications',
            'Native iOS and Android mobile applications',
            'Integration of any hardware device that provides a software interface including cameras, 10 print fingerprint scanners, e-Passport readers, and RFID.'
        ]
    };

    const ourExperience = {
        title: 'Our Experience',
        body: 'Our experience spans over 19 years of successfully delivering mission critical systems.  We have significant expertise in responding to technical coding challenges, having developed a unique approach and infrastructure that allows us to produce and deliver quickly for both one-day and multi-week efforts.  This resulted in SimonComputing named as one of the awardees for the DHS FLASH Agile BPA, and we have since increased our delivery capabilities with newer tools and simplified processes.'
    };

    const additonalContent = (
        <div className='additional-content'>
            <ContentSection {...whatWeDeliver} />
            <ContentSection {...ourExperience} />
            <ContentSection {...tools} />
        </div>
    );

    return (
        <ServiceDisplay 
            serviceId='agile-devsecops'
            title={title}
            summary={summary}
            graphic={<AgileDevGraphic width={450}/>}
            additonalContent={additonalContent}
        />
    );
};

export default AgileDevelopment;