import * as React from 'react';
import { Remove, Add, Check } from '@mui/icons-material';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';

import dhsLogo from '../../media/clients/dhs-logo.png';
import PageHeader from '../../common/PageHeader/PageHeader';
import { ClientPageContent, dhsContent, docContent } from './utils';

const Clients: React.FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [content, setContent] = React.useState<ClientPageContent>();

    const [expanded, setExpanded] = React.useState<number>();

    React.useEffect(() => {
        setExpanded(undefined);

        if (id == 'doc') {
            setContent(docContent);
        } else if (id == 'dhs') {
            setContent(dhsContent);
        } else {
            navigate('/doc', { replace: true });
        }
    }, [id]);
    
    const handleClick = (idx: number) => {
        if (expanded === idx) {
            setExpanded(undefined);
        } else {
            setExpanded(idx);
            const anchor = document.getElementById(`contract-${idx}`);
            if (anchor) anchor.scrollIntoView();
        }
    };

    if (content == undefined) {
        return (
            <div className='client-page'>
                <CircularProgress />
                <Typography>Loading Client Details</Typography>
            </div>
        );
    }

    return (
        <div className='client-page'>
            <PageHeader title={content.title} preTitle='Our Clients' />
            <div className='content' >
                <Grid container direction='row' spacing={{ xs: 2, md: 3 }} style={{ paddingBottom: '40px' }}>
                    <Grid item xs={12} md={2}>
                        <img src={dhsLogo} alt='DHS Logo' width='150px'/>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Typography className='quote'>&quot;{content.quote.text}&quot;</Typography>
                        <Typography textAlign='right'>{content.quote.name}</Typography>
                        <Typography textAlign='right'>{content.quote.dept}</Typography>
                    </Grid>
                </Grid>
                {content.content.map((item, idx) => (
                    <motion.div
                        className='m-div'
                        id={`contract-${item.title}`}
                        key={`contract-${idx}`}
                        initial={{ opacity: 0 , x: 100}}
                        animate={{ opacity: 1 , x: 0}}
                        transition={{ duration: .8, delay: (idx + 1) * .1 }}
                    >
                        <Accordion className='accordion' expanded={expanded == idx}>
                            <AccordionSummary 
                                id={`link-${item.title}`}
                                className='accordion-summary'
                                expandIcon={expanded === idx
                                    ? <Remove className='accordion-summary-icon' />
                                    : <Add className='accordion-summary-icon' />
                                }
                                onClick={() => handleClick(idx)}
                            >
                                <Typography variant='h6'>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='accordion-details'>
                                <Typography className='accordion-detail-title'>{item.subtitle}</Typography>
                                <Typography>Tasks include:</Typography>
                                <div className='list'>
                                    {item.list.map((value) => (
                                        <div className='list-item' key={`list-item-${value}`}>
                                            <Check className='check-icon'/>
                                            <Typography className='list-text'>{value}</Typography>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Clients;