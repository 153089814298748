import { Chip } from '@mui/material';
import React from 'react';

interface Props {
    tag: string;
    marginRight: boolean;
    small?: boolean;
    handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => void;
}

/**
 * 
 * @param props 
 * 
 * tag: tag name
 * marginRight: if margin should be added
 * small: chip size, small or regular
 * handleClick: tag click handler
 * 
 * @returns 
 */
const TagChip: React.FC<Props> = props => {

    const { 
        tag, 
        marginRight=false, 
        small=false,
        handleClick
    } = props;

    return (
        <Chip className={`tag-chip ${marginRight && 'margin-right'}`}
            label={tag}
            variant='outlined'
            size={small? 'small' : undefined}
            color='primary'
            onClick={handleClick? 
                (event) => handleClick(event, tag) 
                : undefined
            }
        />
    );
};

export default TagChip;