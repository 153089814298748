import * as React from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import SEO from '../SEO/SEO';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';


interface PageHeaderProps {
    /**
     * Page title. Also used for SEO.
     * @required
     */
    title: string;
    /**
     * Text to be placed above title.
     * @optional
     */
    preTitle?: string;
    /**
     * Subtitle. Brief summary about page.
     * @optional
     */
    subtitle?: string;
}

type PageHeaderType = 'default' | 'clients-contracts' | 'services';

/**
 * Page header component. Includes SEO. 
 * Will dynamically determine background image based on route.
 * 
 * @param props 
 * @returns 
 */
const PageHeader: React.FC<PageHeaderProps> = props => {

    const {
        title, 
        preTitle,
        subtitle
    } = props;

    const { pathname } = useLocation();

    const [type, setType] = React.useState<PageHeaderType>('default');

    React.useEffect(() => {
        if (pathname.startsWith('/clients') || pathname.startsWith('/contracts'))
            setType('clients-contracts');
        else if (pathname.startsWith('/services'))
            setType('services');
        else
            setType('default');
    }, [pathname]);

    return (
        <>
            <SEO title={title}/>
            <div className={`page-header ${type}-background`}>
                <div className='content'>
                    {preTitle &&
                        <Typography className='pre-title'>{preTitle}</Typography>
                    }
                    <Typography className='title'>{title}</Typography>
                    {subtitle &&
                        <div className='subtitle-div'>
                            <KeyboardDoubleArrowRight className='icon' />
                            <Typography className='subtitle'>{subtitle}</Typography>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default PageHeader;