import * as React from 'react';

import { motion } from 'framer-motion';

import { Card, CardActionArea, Grid, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

const Resources: React.FC = () => {

    const resourcePosts = [
        {
            title: 'Internship Program',
            summary: 'Are you looking for an internship that will provide real-world experience? Check out our internship program.',
            path: '/careers/internship',
        }, {
            title: 'Online Interview Preparation',
            summary: 'We are increasingly utilizing online interviews. Here is what you need to know to be successful.',
            path: '/news/2020/08/12/interview-preparations',
        }, {
            title: 'Technical Interviews',
            summary: 'Technical interviews are a common tool for assessing the ability of software developer candidates. Here are some tips to improve your chance of success.',
            path: '/news/2015/11/07/tech-interview',
        }
    ];

    return (
        <div className='resources'>
            <div className='content'>
                <div className='resources-header'>
                    <Typography className='title'>Resources</Typography>
                    <div className='divider'/>
                </div>
                <Grid className='card-list' container spacing={3}>
                    {resourcePosts.map((post, idx)=> (
                        <Grid className='grid-item' item key={`resource-${idx}`} xs={12} sm={6} md={4}>
                            <motion.div
                                style={{width: '100%'}}
                                initial={{ opacity: 0 , x: 50}}
                                whileInView={{ opacity: 1 , x: 0}}
                                transition={{ duration: .8, delay: (idx + 1) * .2 }}
                                viewport={{ once: true }}
                            >
                                <Card className='card'>
                                    <CardActionArea className='card-action' component={Link} to={post.path}>
                                        <Typography className='card-title'>{post.title}</Typography>
                                        <Typography className='card-summary'>{post.summary}</Typography>
                                    </CardActionArea>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default Resources;