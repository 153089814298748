import * as React from 'react';

import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';

const AppLayout: React.FC<React.PropsWithChildren> = (props) => {

    return (
        <>
            <Navigation />
            <div className='app-content'>{props.children}</div>
            <Footer />
        </>
    );
};

export default AppLayout;