import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
    /**
     * Page title
     */
    title: string;
    /**
     * Page description
     */
    description?: string;
}

/**
 * TODO all seo should include description
 * @param props
 * @returns 
 */
const SEO: React.FC<Props> = props => {

    const { title, description = '' } = props;

    const siteTitle = 'SimonComputing';

    return (
        <Helmet
            htmlAttributes={{ lang: 'en' }}
            title={title}
            titleTemplate={`%s | ${siteTitle}`}
            meta={[
                { name: 'description', content: description },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:type', content: 'website' },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:creator', content: siteTitle },
                { name: 'twitter:title', content: title },
                { name: 'twitter:description', content: description },
            ]}
        />
    );
};

export default SEO; 