import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

import { Post } from '../../../@types';
import TagChip from './TagChip';
import { createPostUrl, formatDate } from '../../../common/Utils/utils';

import ReactMarkdown from 'react-markdown';
import 'highlight.js/styles/github-dark.css';

interface Props {
    post: Post;
    elevated?: boolean;
    blurb?: boolean;
    handleTagClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => void;
}

/**
 * 
 * @param props 
 * 
 * post: Post - post object
 * elevated: boolean - if elevated variant
 * blurb: boolean - if blurb should be displayed
 * handleTagClick: function - tag click handler
 * 
 * @returns 
 */
const PostCard: React.FC<Props> = props => {

    const { 
        post, 
        elevated=false, 
        handleTagClick, 
        blurb=true 
    } = props;

    const { 
        title, 
        thumbnail, 
        thumbnailAlt, 
        date, 
        slug 
    } = post.metadata;
    
    const tags = post.metadata.tags.split(' ').map(str => str.split('-').join(' '));
    const sanitizedBlurb = post.content.slice(0,70).replace(/(\r\n|\n|\r)/gm, ' ').replace(/\*/g, '') + '...';

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(createPostUrl(date, slug));
    };

    const elevatedClass = elevated? 'elevated' : 'flat';

    return (
        <Card className={`post-card ${elevatedClass}`} 
            elevation={elevated? 2 : 0} 
            onClick={handleClick}
        >
            <CardMedia className={`pc-thumbnail ${elevatedClass}`}
                image={`/content-images/${thumbnail}`}
                component='img'
                alt={thumbnailAlt}
            />
            <CardContent className={`pc-content ${elevatedClass}`}>
                <div>
                    <Typography className='pc-title' onClick={handleClick}>
                        {title}
                    </Typography>
                    <Typography className='pc-date'>
                        {formatDate(date)}
                    </Typography>
                    {blurb &&
                        <ReactMarkdown className='pc-blurb'>
                            {sanitizedBlurb}
                        </ReactMarkdown>
                    }
                </div>
                {handleTagClick && 
                    <div className='pc-tag-chips'>
                        {tags.map((tag, i) => 
                            <TagChip 
                                key={`tag-${i}`} 
                                tag={tag} 
                                marginRight={i < tags.length-1}
                                handleClick={handleTagClick}
                                small
                            />
                        )}
                    </div>
                }
            </CardContent>
        </Card>
    );
};

export default PostCard;