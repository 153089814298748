import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import CEOMessage from './CEOMessage';
import OurCulture from './OurCulture';
import PageHeader from '../../common/PageHeader/PageHeader';
import LogoList from '../../common/LogoList/LogoList';
import { partners } from '../../common/Utils/utils';
import { ReactComponent as AboutGraphic } from '../../media/about/about-graphic.svg';

const title = 'About Us';

const subtitle = 'We Simplify. We Lead. We Deliver.'

const p1 = 'SimonComputing, Inc. is an SBA Certified Woman Owned Small Business (WOSB) information technology company, located in Alexandria, Virginia, established in 2002.'

const p2 = 'Most of our software supports the US government\'s mission to bolster national security. Some of our clients include DHS, U.S. Customs and Border Protection(CBP), the Cybersecurity and Infrastructure Security Agency (CISA), and the U.S. Citizenship and Immigration Services (USCIS). Our projects help expedite legal travel into the United States while maintaining safety at the border. They    include public facing websites for multiple Trusted Traveler Programs, Large System Modernizations, Cybersecurity, Cloud Migration, and Mobile Applications. We also specialize in innovative Data Analytics, Data Visualization, and Artificial Intelligence.';

const About: React.FC = () => {

    return (
        <div className='about'>
            <PageHeader title={title} subtitle={subtitle} />
            <div className='overview'>
                <Grid container columnSpacing={10}>
                    <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                        <Typography className='body'>{p1}</Typography>
                        <Typography className='body'>{p2}</Typography>
                    </Grid>
                    <Grid className='graphic-section' item container xs={12} md={5} order={{ xs: 1, md: 2 }}>
                        <AboutGraphic className='about-graphic'/>
                    </Grid>
                </Grid>
            </div>
            <OurCulture/>
            <LogoList list={partners} showButton buttonLabel='Partner With Us'/>
            <CEOMessage/>
        </div>
    );
};

export default About;