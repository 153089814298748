import * as React from 'react';
import { motion } from 'framer-motion';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import Carousel, { CarouselImage } from '../../common/Carousel/Carousel';
import { ReactComponent as CompellingIcon } from '../../media/careers/icons/careers-compelling.svg';
import { ReactComponent as FunIcon } from '../../media/careers/icons/careers-fun.svg';
import { ReactComponent as GrowthIcon } from '../../media/careers/icons/careers-growth.svg';
import { ReactComponent as RightIcon} from '../../media/careers/right-icon.svg';
import { ReactComponent as StaffIcon } from '../../media/careers/icons/careers-staff.svg';
import { ReactComponent as TechIcon } from '../../media/careers/icons/careers-tech.svg';
import img1 from '../../media/careers/photos/join-1.jpg';
import img4 from '../../media/careers/photos/join-4.jpg';
import joinPinstripes from '../../media/careers/photos/join-pinstripes.jpg';
import joinPaintball from '../../media/careers/photos/join-paintball.jpg';
import theme from '../../styles/theme';

const images: CarouselImage[] = [
    { src: joinPinstripes, alt: 'Group photo of 2021 interns and employees outside of pinstripes' },
    { src: img1, alt: 'Simon Woo and employees enjoying food at a restaurant' },
    { src: img4, alt: 'Several employees around a poker table. Two employees are high fiving' },
    { src: joinPaintball, alt: 'Group photo of 2021 interns and employees after paintball' },
];

const p1 = 'Consider a career at SimonComputing, where we build software that protects our country and used by millions of people every day while making their lives easier.\n\n'
            + 'SimonComputing offers opportunities for each individual to make a difference in the company. We work hard to take care of our people, make work interesting and create'
            + ' opportunities for career advancement. When you join SimonComputing, you are joining an elite team of software professionals highly regarded by our clients.';

const keyPoints = [
    {
        title: 'Perform Exciting Work',
        blurb: 'We use all the latest tools and technologies, aligning with the U.S. Digital Services playbook for agile development, user-centered design, and cloud computing.'
    }, {
        title: 'Become Involved In Our Technology Innovations Lab (TIL)',
        blurb: 'We invest significantly in the TIL to stimulate creativity and innovation for employees to grow and bring new ideas to their products and solutions.'
    }, {
        title: 'Work On Impactful Projects',
        blurb: 'We are very selective of the projects we support, preferring to work with mission-critical programs that protect our country and involve modernization, transformation, and innovation.'
    }, {
        title: 'Enjoy What You Are Doing',
        blurb: 'Our teams are highly collaborative and our unique culture provides encouragement and inspiration for you to fulfill your goals'
    }
];

const cutOut1 = 'Do you enjoy working with new technologies on agile development teams?';

const cutOut2 = 'An amazing, vibrant culture where people really care about you!';

const perks = 'The perks of being a SimonComputing employee don\'t stop at collaborating with great people on stimulating projects. We also offer a full range of benefits to support our employees:';

const benefitList = [
    'Free Medical, Dental, and Vision Insurance',
    'Competitive Salaries',
    '401k Retirement Plan',
    'Paid leave (holiday, sick, and vacation)',
    'Employee referral bonuses',
    '529 college savings plan',
    'Education reimbursements',
    'Fun team building events',
];

const offerList = [
    {
        icon: <CompellingIcon className='illustration' width={90} height={90}/>,
        title: 'Compelling Work',
        summary: 'Our software protects our borders, facilitates legitimate commerce, and processes millions of people every day. We build the software, deliver it and help users get the most out of it. It is rewarding and enlightening to see how the user community uses our software.'
    }, {
        icon: <GrowthIcon className='illustration' width={90} height={90}/>,
        title: 'Professional Growth',
        summary: 'Our employees are the reason for our success. We know what it\'s like to feel anonymous in a large company, and we want you to feel like family. We create a career plan for each person and do everything we can to support you on your personal journey. Your career plan becomes a part of our strategy when building teams, defining roles and assigning tasks. Everyone has an opportunity to grow and move forward.'
    }, {
        icon: <TechIcon className='illustration' width={90} height={90}/>,
        title: 'Current Technologies',
        summary: 'Using open source technologies, we work on everything from web applications development to biometric devices. Our current technology stack includes React/Redux/TypeScript, Material UI, Spring Boot, JPA/Hibernate, PostgreSQL, Visual Studio Code, Eclipse, Git and the Atlassian Suite.'
    }, {
        icon: <FunIcon className='illustration' width={90} height={90}/>,
        title: 'Fun Events',
        summary: 'When it\'s time to relax, we want to give you experiences you will remember. Our summer and winter parties have taken us to theme parks, baseball games, paintball outings, tubing down the Shenandoah River, and a couple of evenings at the Kennedy Center.'
    }, {
        icon: <StaffIcon className='illustration' width={90} height={90}/>,
        title: 'Great Staff',
        summary: 'The most frequently cited reason for enjoying the work here is working with our staff. We are straight-forward and hard working people who are passionate about our work.'
    }
];

const WhyJoinUs: React.FC = () => {

    const renderImageList = () => {
        return (
            <Grid className='image-list' item xs={4}>
                {images.map((obj, idx) => (
                    <motion.img
                        className='img'
                        key={`img-${idx}`}
                        src={obj.src}
                        alt={obj.alt}
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: .8 }}
                        viewport={{ once: true }}
                    />
                ))}
            </Grid>
        );
    };

    return (
        <div className='why-join-us'>
            {!useMediaQuery(theme.breakpoints.up('md')) && <Carousel images={images}/>}
            <Grid className='default-content' container>
                {useMediaQuery(theme.breakpoints.up('md')) && renderImageList()}
                <Grid className='text' item xs={12} md={8}>
                    <Typography className='title'>Why Join Us?</Typography>
                    <Typography className='body'>{p1}</Typography>
                    <motion.div 
                        className='cut-out'
                        initial={{ opacity: 0 , x: 100}}
                        whileInView={{ opacity: 1 , x: 0}}
                        transition={{ delay: .5, duration: .8 }}
                        viewport={{ once: true }}
                    >
                        <RightIcon width={40} height={40}/>
                        <Typography className='ct-text'>{cutOut1}</Typography>
                    </motion.div>
                    <br/>
                    {keyPoints.map((point, idx) => (
                        <div className='key-points' key={`key-point-${idx}`}>
                            <Typography className='section-title'>{point.title}</Typography>
                            <Typography className='body'>{point.blurb}</Typography>
                        </div>
                    ))}
                    <br/>
                    <Typography className='title'>We Value Our Staff </Typography>
                    <Typography className='body'>{perks}</Typography>
                    <div className='list'>
                        {benefitList.map((benefit, idx) => (
                            <div className='list-item' key={`benefit-${idx}`}>
                                <CheckIcon className='check-icon'/>
                                <Typography className='list-text'>{benefit}</Typography>
                            </div>
                        ))}
                    </div>
                    <motion.div 
                        className='cut-out'
                        initial={{ opacity: 0 , x: 100}}
                        whileInView={{ opacity: 1 , x: 0}}
                        transition={{ delay: .5, duration: .8 }}
                        viewport={{ once: true }}
                    >
                        <RightIcon width={40} height={40}/>
                        <Typography className='ct-text'>{cutOut2}</Typography>
                    </motion.div>
                </Grid>
                <Grid className='text we-offer' item xs={12}>
                    <Typography className='title'>We Offer...</Typography>
                    <div className='list'>
                        {offerList.map((obj, idx) => (
                            <div className='desc-section' key={`we-offer-${idx}`}>
                                {obj.icon}
                                <div className='content'>
                                    <Typography className='header'>{obj.title}</Typography>
                                    <Typography className='body'>{obj.summary}</Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default WhyJoinUs;