import * as React from 'react';
import { motion } from 'framer-motion';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import newsPosts from '../News/posts.json';
import FeaturedPosts from '../News/FeaturedPosts';
import SEO from '../../common/SEO/SEO';
import LogoList from '../../common/LogoList/LogoList';
import { certifications, partners } from '../../common/Utils/utils';
import StyledButton from '../../common/StyledButton/StyledButton';
import ServiceCard from './ServiceCard';
import agileImg from '../../media/services/images/agile-development.jpeg';
import visImg from '../../media/services/images/data-visualization.jpeg';
import cyberImg from '../../media/services/images/cyber-security.jpeg';
import systemImg from '../../media/services/images/system-modernization.jpeg';
import cloudImg from '../../media/services/images/cloud-migration.jpeg';

const heroBlurb = 'Where\nInnovation\nMeets\nImpact.';

const serviceHighlights = [
    {
        title: 'Agile Development',
        link: '/services/agile-development',
        src: agileImg,
        alt: 'A team of developers discussing in front of a computer'
    }, {
        title: 'Data Analytics & Visualization',
        link: '/services/data-analytics',
        src: visImg,
        alt: 'Graphs and tables displayed on a laptop and two monitors'
    }, {
        title: 'System Modernization',
        link: '/services/system-modernization',
        src: systemImg,
        alt: 'Server room'
    }, {
        title: 'Cyber Security',
        link: '/services/cyber-security',
        src: cyberImg,
        alt: 'Shield with a lock cutout'
    }, {
        title: 'Cloud Migration',
        link: '/services/cloud-migration',
        src: cloudImg,
        alt: 'Business man using mobile phone with icon network connection'
    }
];

const serviceBtn = 'View All Services';

/**
 * TODO: change the 2 logo lists to a carousel/scrolling list
 * @returns 
 */
const Landing: React.FC = () => {

    const recentPosts = newsPosts.slice(0,3);

    const fadeIn = {
        initial: { opacity: 0 },
        animate: { opacity: 1 }
    };

    const mobile = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <div className='landing'>
            <SEO title='Home'/>
            <div className='hero'>
                <div className='hero-content'>
                    <motion.div
                        className='hero-banner'
                        initial={mobile ? {} : { opacity: 0, x: -100 }}
                        animate={mobile ? {} : { opacity: 1, x: 0 }}
                        transition={{ duration: 1.5 }}
                    >
                        <Typography className='hero-text'>{heroBlurb}</Typography>
                        <div className='actions'>
                            <StyledButton label='About Us' path='/about' variant='contained' color='secondary' />
                            <StyledButton label='Our Clients' path='/clients/dhs' variant='outlined' color='secondary' />
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className='our-services' id='our-services'>
                <div className='title-section'>
                    <Typography className='section-title'>Our Services</Typography> 
                    <motion.div 
                        className='our-services-btn-container'
                        {...fadeIn}
                        transition={{ duration: .9 }}
                    >
                        <StyledButton path='/services' label={serviceBtn} color='secondary' />
                    </motion.div>
                </div>
                <Grid container spacing={2} className='list'>
                    {serviceHighlights.map((service, idx) => (
                        <Grid item xs={12} md={6} lg={4}
                            className='list-item'
                            key={`service-${service.title}`}
                        >
                            <motion.div
                                initial={{ opacity: 0 , y: 50 }}
                                animate={{ opacity: 1 , y: 0 }}
                                transition= {{ duration: .9, delay: ((idx + 1) * .2) + .5 }}
                                className='motion-div'
                            >
                                <ServiceCard {...service} />
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <FeaturedPosts 
                posts={recentPosts} 
                title='Featured News' 
                button={{ title: 'More News', to:'/news' }}
                mode='light'
            />
            <LogoList list={certifications.concat(partners)} showButton buttonLabel='Partner With Us'/>
        </div>
    );
};

export default Landing;