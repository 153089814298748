import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';

export interface StyledButtonProps {
    /**
     * Button label
     * @required
     */
    label: string;
    /**
     * Internal path. Will navigate onClick if provided.
     * @optional
     */
    path?: string;
    /**
     * External path. Will navigate onClick if provided.
     * @optional
     */
    href?: string;
    /**
     * Custom onClick handler. Will be called onClick if provided.
     * @returns void
     * @optional
     */
    onClick?: () => void;
    /**
     * Button color theme
     * @default 'primary'
     */
    color?: 'primary' | 'secondary' | 'error';
    /**
     * Disabled button indicator.
     * Will change the button color to gray and prevent onClick functionality.
     * @default false
     */
    disabled?: boolean;
    /**
     * Arrow icon placement.
     * If provided, will add an arrow icon next to button label.
     * @default undefined
     */
    arrowPlacement?: 'start' | 'end';
    /**
     * Style variant.
     * - `'outlined'`: Default is outlined button. OnHover, changes to contained button.
     * - `'contained'`: Default is contained button. OnHover, changes to outlined button.
     */
    variant?: 'contained' | 'outlined' | 'text';
}

/**
 * Custom styled button to use across application.
 * 
 * @param props 
 * @returns 
 */
const StyledButton: React.FC<StyledButtonProps> = (props) => {

    const {
        label,
        path,
        href,
        color='primary',
        disabled=false,
        arrowPlacement,
        variant='contained'
    } = props;

    const navigate = useNavigate();

    /**
     * Depending on which props are specified, handle button click
     */
    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        } else if (path) {
            navigate(path);
        } else if (href) {
            window.open(href, '_blank');
        }
    };

    return (
        <Button
            className='styled-button'
            variant={variant}
            color={color}
            onClick={onClick}
            disabled={disabled}
            startIcon={arrowPlacement == 'start' ? <KeyboardDoubleArrowLeft /> : undefined}
            endIcon={arrowPlacement == 'end' ? <KeyboardDoubleArrowRight /> : undefined}
            disableElevation
        >
            {label}
        </Button>
    );
};

export default StyledButton;