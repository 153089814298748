import * as React from 'react';

import { Box, Typography } from '@mui/material';

import gsa8aii from '../../../media/contracts/gsa8astarsii.jpg'

const GSA8AII: React.FC = () => {
    return (
        <div className='additional-content'>
            <Box
                className='img'
                component='img'
                src={gsa8aii}
                alt='GSA 8(a) Stars II logo'
            />
            <Typography className='body'>
                The 8(a) STARS II GWAC program aims to promote the use of small businesses for information technology
                (IT) services or IT services-based solutions for the federal government. This contract vehicle is
                designed exclusively for qualifying certified 8(a) small businesses specialized in information technology.
            </Typography>
            <Typography className='ct-title'> Our Contract: </Typography>
            <ul className='body'>
                <li >Constellation I and II</li>
                <li >Functional Area 1: NAICS 541511 - Custom Computer Programming Services</li>
                <li >Base Period of Performance: 08/31/2011 - 08/30/2016</li>
                <li >Five-Year Option Period: 08/31/2016 - 08/30/2021</li>
                <li >DUNS Number: 118287338</li>
            </ul>
            <Typography className='ct-title'> Features of STARS II: </Typography>
            <ul className='body'>
                <li >Multiple award, indefinite delivery, indefinite quantity (IDIQ) contract vehicle</li>
                <li >Five-year base with one five-year option</li>
                <li >$10 billion program ceiling</li>
                <li >Directed Order Authority:Directed task orders up to $14 million each are allowed for federal civilian and Department of Defense activities</li>
            </ul>
            <Typography className='ct-title'> Benefits of STARS II: </Typography >
            <ul className='body'>
                <li >8(a) socioeconomic credit transferrable to the agency</li>
                <li >Pre-competed, easy to use contracts</li>
                <li >Access to proven 8(a) small business technology providers</li>
                <li >A low user access fee (0.75%) built into contractor’s ceiling prices</li>
                <li >Prompt payment terms</li>
            </ul>
            <Typography className='ct-title'> SimonComputing STARS II Program Manager</Typography >
            <div className='body'>
                Danaiya Woo<br />
                <a href="mailto:danaiya@simoncomputing.com">
                    danaiya@simoncomputing.com<br />
                </a>
                <a href="tel:+1-703-914-5454">
                    Phone: 703-914-5454<br />
                </a>
                <a href="tel:+1-703-914-1133">
                    Fax: 703-914-1133<br /><br />
                </a>
                <a href="http://www.gsa.gov/portal/content/105243">
                    GSA 8(a) STARS II website<br />
                </a>
                <a href="https://www.gsa.gov/technology/technology-purchasing-programs/governmentwide-acquisition-contracts/8a-stars-ii-governmentwide-acquisition-contract-gwac#3">
                    Ordering Guide<br />
                </a>
                <a href="https://www.sam.gov/SAM/pages/public/entitySearch/entitySearchEntityOverview.jsf">
                    SimonComputing’s SAM.gov listing
                </a>
            </div>

        </div>
    );
};

export default GSA8AII;