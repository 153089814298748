import * as React from 'react';
import { Autocomplete, Grid, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

import { Post } from '../../@types';
import PostCard from './PostCard/PostCard';
import FeaturedPosts from './FeaturedPosts';
import SEO from '../../common/SEO/SEO';
import posts from './posts.json';


const POSTS_PER_PAGE = 12;

const News: React.FC = () => {

    const featuredPosts = posts.slice(0,3);
    const allTags = Array.from(new Set(posts.map(post => post.metadata.tags ).join(' ').split(' ').sort()));

    const [filteredPosts, setFilteredPosts] = React.useState<Post[]>(posts);
    const [page, setPage] = React.useState<number>(1);
    const [filteredTags, setFilteredTags] = React.useState<string[]>([]);
    const [query, setQuery] = React.useState<string>('');
    
    const tagsFn = (post: Post) => {
        return filteredTags.some((val: string) => post.metadata.tags.includes(val));
    };
    
    const searchFn = (post: Post) => {
        const { title, date, author, tags } = post.metadata;
        const content = post.content;

        return (
            title.toLowerCase().includes(query.toLowerCase()) ||
            date.toLowerCase().includes(query.toLowerCase()) ||
            author.toLowerCase().includes(query.toLowerCase()) ||
            tags.toLowerCase().includes(query.toLowerCase()) ||
            content.toLowerCase().includes(query.toLowerCase())
        );
    };

    React.useEffect(() => {
        let temp = posts;

        if (filteredTags && filteredTags.length > 0)
            temp = temp.filter(post => tagsFn(post));
        if (query)
            temp = temp.filter(post => searchFn(post));

        setFilteredPosts(temp);
        setPage(1);
    }, [filteredTags, query]);

    const getCount = () => {
        return Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
    };

    const slicePosts = () => {
        const start = (page - 1) * POSTS_PER_PAGE;
        return filteredPosts.slice(start, start + POSTS_PER_PAGE);
    };

    const handleChangePage = ( _event: React.ChangeEvent<unknown>, val: number) => {
        setPage(val);
        const anchor = document.getElementById('scroll-anchor');
        if (anchor) anchor.scrollIntoView();
    };

    const handleTags = (_event: React.SyntheticEvent<Element, Event>, val: string[]) => { 
        setFilteredTags(val);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { 
        setQuery(event.target.value);
    };

    const handleTagClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, val: string) => {
        setFilteredTags([val]);
        event.stopPropagation();
    };

    const handleSearchReset = () => {
        setQuery('');
    };

    const BlogPagination = () => (
        <Pagination 
            className='pagination'
            count={getCount()}
            showFirstButton 
            showLastButton 
            page={page} 
            onChange={handleChangePage}
        />
    );

    return (
        <>
            <SEO title='News'/>
            <div className='news-page'>
                <FeaturedPosts 
                    posts={featuredPosts} 
                    title='Featured News'
                    handleTagClick={handleTagClick} 
                    blurb
                />
                <Grid className='functions' container>
                    <div className='search-filter'>
                        <Autocomplete className='filter'
                            multiple
                            size='small'
                            options={allTags}
                            noOptionsText='No Topics Found'
                            value={filteredTags}
                            filterSelectedOptions
                            onChange={handleTags}
                            renderInput={(params) => (
                                <TextField {...params} variant='outlined' placeholder='Topics'/>
                            )}
                        />
                        <TextField className='search'
                            placeholder='Search'
                            size='small'
                            onChange={handleSearch}
                            value={query}
                            InputProps={{
                                className: 'search-input',
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon fontSize='small'/>
                                    </InputAdornment>
                                ),
                                endAdornment: (query && 
                                    <InputAdornment position='end'>
                                        <IconButton className='search-clear-icon' onClick={handleSearchReset}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <BlogPagination />
                </Grid>
                <Grid className='post-grid' id='scroll-anchor' container spacing={3}>
                    {slicePosts().map(post => 
                        <Grid item key={post.id} xs={12} sm={6} md={4} lg={3}>
                            <PostCard post={post} handleTagClick={handleTagClick}/>
                        </Grid>
                    )}
                </Grid>
                <Grid className='functions single' container>
                    <BlogPagination />
                </Grid>
            </div>
        </>
    );
};

export default News;