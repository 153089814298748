import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import PageHeader from '../../common/PageHeader/PageHeader';
import StyledButton from '../../common/StyledButton/StyledButton';
import { applicantProLink } from '../../common/Utils/utils';
import internPhoto from '../../media/internship/internship-2023.jpg';

const title = 'Internship Program';
const subtitle = 'Experience building software in a professional team setting!';

const p2 = 'Every summer we offer an internship program tailored for Computer Science and Computer Engineering students. This is a high quality program run by our Technology Innovations Lab, the organization at SimonComputing responsible for the continuous refinement of our software development processes and tools.';
const p3 = 'This is a 9 week, fully immersive software development experience taking you from beginning to end of the software development process. It follows the same process our software development teams go through to build high quality products.';
const p4 = 'We use current open-source technologies including:';

const techList = [
    { label: 'Front End Technologies', value: 'React/Redux/TypeScript/Material UI' },
    { label: 'Back End Technologies', value: 'SpringBoot/Java/JPA/Hibernate' },
    { label: 'Databases', value: 'PostgreSQL, Snowflake' },
    { label: 'Cloud Environment', value: 'AWS Cloud, Kubernetes' },
    { label: 'CI/CD Pipeline', value: 'Git/Gitlab, Jenkins, SonarQube' }
];
const p5 = 'This training program will expose you to our Agile software development process which is informed by a number of different Agile disciplines including: Scrum, Extreme Programming, Lean Software, Google Venture\'s Design Sprint and Event Storming to name a few.';
const p6 = 'Prerequisite: 2-3 years Java and/or C programming experience. Experience in similar languages will be considered.';

const Internship: React.FC = () => {
    return (
        <div className='internship'>
            <PageHeader title={title} subtitle={subtitle}/>
            <div className='overview'>
                <Grid container columnSpacing={10}>
                    <Grid className='text-section' item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                        <Typography className='body'>{p2}</Typography>
                        <Typography className='body'>{p3}</Typography>
                        <Typography className='body'>{p4}</Typography>
                        {techList.map((item, idx) => (
                            <div
                                className='list-item'
                                key={`tech-${idx}`}
                                style={idx == techList.length - 1
                                    ? { marginBottom: 20 }
                                    : undefined
                                }>
                                <CheckIcon className='check-icon' />
                                <Typography className='list-text' fontWeight='bold'>{item.label}:</Typography>
                                <Typography className='list-text'>{item.value}</Typography>
                            </div>
                        ))} 
                        <Typography className='body'>{p5}</Typography>
                        <Typography className='body'>{p6}</Typography>
                        <StyledButton label='Apply Now' href={applicantProLink} />
                    </Grid>
                    <Grid className='graphic-section' item container xs={12} md={5} order={{ xs: 1, md: 2 }}>
                        <img src={internPhoto} alt='Internship' />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Internship;