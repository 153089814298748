import * as React from 'react';

import { Box, Typography } from '@mui/material';

import ciosp3 from '../../../media/contracts/cio-sp3sb.png'

const CIOSP3: React.FC = () => {
    return (
        <div className='additional-content'>
            <Box
                className='img'
                component='img'
                src={ciosp3}
                alt='CIO SP 3 logo'
            />
            <br/><br/>
            <Typography className='body'>
            Chief Information Officer-Solutions and Partners 3 (CIO-SP3) is a 10-year IDIQ Government-Wide Acquisition Contract (GWAC) with a 15-year period of performance that has been designated as Best in Class (BIC) by the Office of Management and Budget (OMB). 
            CIO-SP3 Contract Holders have gone through a rigorous source selection process prior to award, ensuring the pool of contractors are the best-of-the-best, resulting in significant savings in time, money and resources.
            </Typography>
        </div> 
    );
};

export default CIOSP3;