import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, List, ListItemButton, ListItemText, Collapse, Drawer } from '@mui/material';
import { Menu as MenuIcon, ExpandMore, ExpandLess, Close as CloseIcon } from '@mui/icons-material';

import { getNavItems, isActive } from './utils';
import { ActiveNavItem, NavItem } from '../../../@types';

const Sidebar: React.FC<{ activeNavItem: ActiveNavItem }> = (props) => {

    const { activeNavItem } = props;

    const navigate = useNavigate();
    const navItems = getNavItems();

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [expandedIdx, setExpandedIdx] = React.useState<number | null>(null);

    const openDrawer = () => {
        setExpandedIdx(null);
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setExpandedIdx(null);
    };

    /**
     * Navigate to path onClick
     * @param path 
     */
    const onClick = (idx: number, path?: string) => {
        if (path) {
            closeDrawer();
            navigate(path);
        } else {
            setExpandedIdx(expandedIdx === idx ? null : idx);
        }
    };
    
    const onClickChild = (path?: string) => {
        if (path) {
            setDrawerOpen(false);
            navigate(path);
        }
    };
    
    return(
        <>
            <IconButton onClick={openDrawer} data-testid='sidebar-open'>
                <MenuIcon className='sidebar-toggle-icon'/>
            </IconButton>
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={closeDrawer}
                PaperProps={{ className: 'sidebar-drawer' }}
            >
                <div className='sidebar-actions'>
                    <IconButton onClick={closeDrawer} data-testid='sidebar-close'>
                        <CloseIcon />
                    </IconButton>
                </div>
                <List className='sidebar-list'>
                    {navItems.map((navItem, idx) => {
                        let className = 'sidebar-list-item';
                        if (isActive(navItem, activeNavItem))
                            className += ' active';

                        return (
                            <div key={navItem.title}>
                                <ListItemButton
                                    onClick={() => onClick(idx, navItem.path)}
                                    className={className}
                                    data-testid={`nav-item-${navItem.title}`}
                                >
                                    <ListItemText primary={navItem.title} />
                                    {navItem.children &&
                                        (expandedIdx === idx ? <ExpandLess /> : <ExpandMore />)
                                    }
                                </ListItemButton>
                                <Collapse in={expandedIdx === idx} timeout='auto' unmountOnExit>
                                    <List component='div' disablePadding>
                                        {navItem.children?.map((child: NavItem) => {
                                            let childClassName = 'sidebar-sub-list-item';
                                            if (isActive(child, activeNavItem))
                                                childClassName += ' active';

                                            return (
                                                <ListItemButton
                                                    className={childClassName}
                                                    key={child.title}
                                                    sx={{ pl: 4 }}
                                                    onClick={() => onClickChild(child.path)}
                                                    data-testid={`sub-nav-item-${child.title}`}
                                                >
                                                    <ListItemText primary={child.title} />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            </div>
                        );
                    })}
                </List>
            </Drawer>
        </>
    );
};

export default Sidebar;
