import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { ReactComponent as QualityIcon } from '../../media/about/quality-icon.svg';
import StyledButton from '../../common/StyledButton/StyledButton';

const sectionTitle = 'Our Unique Culture';

const greatWork = {
    title: 'We\'re Great to Work With',
    body: [
        'SimonComputing\'s unique culture cultivates innovation, creativity, and great solutions while providing a long-lasting foundation for strong relationships with our clients and business partners.',
        <span key='we-collaborate'><b>We Collaborate.</b> Teamwork, transparency, and open communication allow us to work efficiently, share ideas, and leverage the skills of our diverse team.</span>,
        <span key='we-get-things-done'><b>We Get Things Done.</b>  We&#39;re focused on results and go beyond “checking the boxes”.  We&#39;re thorough and detail-oriented, yet we also look at the big picture:  Does this improve business results?  Are things more efficient?  Is usability much better?  Can we do this in a different and better way?</span>,
        <span key='commited-to-success'><b>We&#39;re Strongly Committed To Your Success.</b> We&#39;re here for you. We go the extra mile by providing incredible support and service, many times more than what is expected.</span>
    ],
    button: 'Work With Us'
};

const softwareDevs = {
    title: 'In the Company of Software Developers',
    body: 'Our unique culture among the best software developers embodies humility, focuses on the things that matter, and opens collaboration to get to the best solutions. ' 
        + 'Our team members are straightforward and focused on getting things done.\n\nWe work hard to create a productive environment that captures the best attributes of that culture and make ' 
        + 'it the way we run our entire business. We emphasize delivering working software, testing our ideas and improving the way we do our work.',
    button: 'Career Opportunities'
};

const missionAndValues = {
    title: 'Mission and Values',
    statement: 'Our mission is to deliver high quality software in the simplest way possible.',
    body: 'At the heart of our culture is our mission and values. We believe that we can achieve great success by doing the right thing and creating value with our work. This idea defines our priorities.',
    listTitle: 'We strive to:',
    list: [
        'Care for our clients, our people, and our community',
        'Interact with honesty, integrity and respect',
        'Communicate clearly and concisely when writing, speaking, and coding',
        'Care about quality and vigorously pursue reliability, maintainability, usability and performance in our products',
        'Apply simplicity, organization and clarity to everything that we do',
        'Continuously improve as individuals and as a company'
    ]
};

const qualityPledge = {
    title: 'Our Quality Pledge',
    body: 'SimonComputing, Inc. aspires to ensure confidentiality, integrity, and availability of information and assets. We seek to provide our customers a consistent experience by committing to process-driven '
        + 'delivery of software products and services that exceed expectations in performance, schedule, and value.',
};

const OurCulture: React.FC = () => {

    return (
        <div className='our-culture'>
            <Typography className='abt-section-title'>{sectionTitle}</Typography>
            <div className='content'>
                <Grid className='top' container columnSpacing={10}>
                    <Grid item md={6} xs={12} order={{xs: 1, md: 1}}>
                        <div className='great-work'>
                            <Typography className='title'>{greatWork.title}</Typography>
                            <div>
                                {greatWork.body.map((value, idx) => (
                                    <Typography className='body' key={`point-${idx}`}>{value}</Typography>
                                ))} 
                            </div>
                            <StyledButton label={greatWork.button} path='/contact' color='secondary' />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} order={{xs: 2, md: 1}}>
                        <div className='mission-values'>
                            <Typography className='title'>{missionAndValues.title}</Typography>
                            <Typography className='body hand-written'>{missionAndValues.statement}</Typography>
                            <Typography className='body'>{missionAndValues.body}</Typography>
                            <Typography className='body bold'>{missionAndValues.listTitle}</Typography>
                            <div className='list'>
                                {missionAndValues.list.map((value, idx) => (
                                    <div className='list-item' key={`mission-${idx}`}>
                                        <CheckIcon className='check-icon'/>
                                        <Typography className='list-text'>{value}</Typography>
                                    </div>
                                ))} 
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={10}>
                    <Grid item md={6} xs={12} order={{xs: 4, md: 1}}>
                        <div className='our-team'>
                            <Typography className='title'>{softwareDevs.title}</Typography>
                            <Typography className='body'>{softwareDevs.body}</Typography>
                            <StyledButton label={softwareDevs.button} path='/careers' />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} order={{xs: 3, md: 1}}>
                        <div className='quality-pledge'>
                            <div className='illustration'><QualityIcon className='icon'/></div>
                            <div>
                                <Typography className='title'>{qualityPledge.title}</Typography>
                                <Typography className='body'>{qualityPledge.body}</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default OurCulture;