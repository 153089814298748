import * as React from 'react';
import { Box, Typography } from '@mui/material';

import danaiyaWoo from '../../media/about/danaiyawoo.jpg';

const CEOMessage: React.FC = () => {
    return (
        <div className='ceo-message'>
            <div className='box'>
                <Typography className='section-title'>A Message From Our CEO</Typography>
                <div className='content'>
                    <div className='photo-section'>
                        <Box
                            component='img'
                            height={300}
                            src={danaiyaWoo}
                            alt='Danaiya Woo'
                        />
                        <div className='caption'>
                            <Typography className='body bold'>Danaiya Woo</Typography>
                            <Typography className='body'>President and CEO</Typography>
                        </div>
                    </div>
                    <div className='body'>
                        SimonComputing defines success around a few basic goals:
                        <br/>
                        <ul>
                            <li>Deliver exceptional service to our client.</li>
                            <li>Hire the best.</li>
                            <li>Keep everything we do clear, concise and simple.</li>
                        </ul>
                        We seek to build lasting partnerships that create mutual benefit. Those are the relationships
                        that grow and become greater than we each could achieve alone.
                        <br/><br/>
                        As a small business we offer competitive opportunities and benefits focused on the individual.
                        When you join our company you are joining an elite team of software professionals highly
                        regarded by our clients. You can rest assured you were hired to grow in your personal career
                        and in our team. Our high standards can only be maintained with people willing to constantly
                        learn, grow and meet the next challenge with an inextinguishable spirit.
                        <br/><br/>
                        Get to know SimonComputing by navigating through our website or contacting us directly at
                        info@simoncomputing.com. Whether you are looking for a potential partner, or seeking
                        employment we&apos;d love to hear from you. On behalf of everyone at SimonComputing, we look
                        forward to a prosperous relationship with you.
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CEOMessage;