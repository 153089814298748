import * as React from 'react';

import ContentSection from './ContentSection';
import ServiceDisplay from '../ServiceDisplay';
import { ReactComponent as SystemModernizationGraphic } from '../../../media/services/illustrations/system-modernization.svg';

const SystemModernization: React.FC = () => {

    const title = 'System Modernization';
    const summary = 'We have been transforming systems for over 20 years, providing our clients with modern, innovative solutions that improve business results, increase usability, and simplify operations.  We are strongly aligned with the practices of the U.S. Digital Services Playbook - embracing many of the concepts even before USDS was formed - with our focus on agile, open, simple, flexible, and intuitive.  We have a strong focus on User Research and User Centered Design to get it right the first time and exceed all expectations.';

    const ourExperience = {
        title: 'Our Experience',
        body: 'We have over 20 years of delivering large modernization projects spanning many technologies and platforms including large-scale agile development, mobile applications, biometrics, and hand-held devices.  We have built systems involving hundreds of millions of users and records for land, air, and sea solutions involving national security.  We have taken senior leadership role on very large programs, ensuring complete delivery, providing innovation, and furthering the mission of our government.  At CBP, for example, we initially developed architecture solutions for a large program which were so well received that we founded a program-wide architecture review board across all projects that still exists today.'
    }
    const ourApproach = {
        title: 'Our Approach',
        list: [
            <span key='our-approach-line-1'>Leverage the <b>SimonComputing Modernization Toolkit</b> - based on lessons learned from transforming large Federal government programs</span>,
            'Special Devices Team handles a wide range of technologies for complex systems, such as mobile applications, biometrics, and RPA',
            'User-friendly interface grounded in research and design techniques',
            'State of the art software development processes that drive high quality results',
            'Cybersecurity fully embedded from start to finish throughout our agile process '
        ]
    };

    const additonalContent = (
        <div className='additional-content'>
            <ContentSection {...ourExperience} />
            <ContentSection {...ourApproach} />
        </div>
    );

    return (
        <ServiceDisplay 
            serviceId='system-modernization'
            title={title}
            summary={summary}
            graphic={<SystemModernizationGraphic width={400}/>}
            additonalContent={additonalContent}
        />
    );
};

export default SystemModernization;