import React from 'react';
import { Box, Link, Typography } from '@mui/material';

interface Image {
    img: JSX.Element | string, 
    alt: string,
    href?: string
}

interface ContentSectionProps {
    title?: string;
    body?: string;
    list?: (string | JSX.Element)[];
    imgList?: Image[];
}

const ContentSection: React.FC<ContentSectionProps> = (props) => {

    const { title, body, list, imgList } = props;

    return (
        <div>
            <Typography className='title'>{title}</Typography>
            <div className='body'>
                {body}
                {list &&
                    <>
                        {body && <br/>}
                        <ul className='list'>
                            {list.map((value, idx) => (
                                <li className='list-item' key={`list-item-${idx}`}>{value}</li>
                            ))}
                        </ul>
                    </>
                }
            </div>
            {imgList &&
                <div className='flex-row' style={{flexWrap: 'wrap'}}>
                    {imgList.map((obj) => (
                        <Link
                            key={`logo-${obj.alt}`} 
                            href={obj.href} 
                            target='_blank' 
                            rel='noopener noreferrer'
                        >
                            {typeof obj.img === 'string' ?
                                <Box
                                    className='img limit-width'
                                    component='img'
                                    style={{marginRight: '20px'}}
                                    src={obj.img}
                                    alt={obj.alt}
                                />
                                : obj && <>{obj.img}</>
                            }
                        </Link>
                    ))}
                </div>
            }
        </div>
    );
}

export default ContentSection;