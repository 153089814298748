import * as React from 'react'; 

import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapContainer: React.FC = () => {
    const mapContainerStyles = {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    const mapStyles = [{
        featureType : 'all',
        stylers : [ {
            'saturation' : 0
        }, {
            'hue' : '#E7ECF0'
        } ]
    }, {
        featureType : 'road',
        stylers : [ {
            'saturation' : -70
        } ]
    }, {
        featureType : 'transit',
        stylers : [ {
            'visibility' : 'off'
        } ]
    }, {
        featureType : 'poi',
        stylers : [ {
            'visibility' : 'off'
        } ]
    }, {
        featureType : 'water',
        stylers : [ {
            'visibility' : 'simplified'
        }, {
            'saturation' : -60
        } ]
    }];


    const location = {
        address: '5350 Shawnee Rd STE 200, Alexandria, VA 22312',
        position: {lat: 38.80696120288506, lng: -77.16707446487693},
        lat: 38.80696120288506,
        lng: -77.16707446487693
    };

    const googleMapsLink = 'https://www.google.com/maps?f=q&source=embed&hl=en&geocode&q=simoncomputing&aq&sll=38.804835,-77.046921&sspn=0.102738,0.162392&g=Alexandria,+VA&ie=UTF8&t=m&cid=15575873701442928776&hq=simoncomputing&hnear&ll=38.811557,-77.166767&spn=0.011704,0.024033&z=15&iwloc=A';

    const title = 'SimonComputing, Inc.';
    const linkTitle = 'Get Directions';

    return (
        <div className='map-container'>
            <LoadScript googleMapsApiKey='AIzaSyBFVZPF1FnGd3BIxf8-WQogAkjru-mQxSQ'>
                <GoogleMap
                    mapContainerStyle={mapContainerStyles}
                    zoom={13}
                    center={location.position}
                    options = {{
                        styles: mapStyles
                    }}
                >
                    <Marker position={location.position} title='SimonCompputing, Inc.'/>
                    <InfoWindow position={{lat: location.position.lat +.006, lng: location.position.lng}}>
                        <div className='map-info-box'>
                            <h1 className='title'>{title}</h1>
                            <a href={googleMapsLink} target='_blank' rel='noopener noreferrer'>{linkTitle}</a>
                        </div>
                    </InfoWindow>
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapContainer;