import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

import { getNavItems, isActive } from './utils';
import { ActiveNavItem, NavItem } from '../../../@types';

interface MenuAnchor {
    anchorEl: HTMLElement;
    idx: number;
}

const Header: React.FC<{ activeNavItem: ActiveNavItem }> = (props) => {

    const { activeNavItem } = props;

    const navigate = useNavigate();
    const navItems = getNavItems();

    const [menuAnchor, setMenuAnchor] = React.useState<MenuAnchor | null>(null);

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const openMenu = (event: React.MouseEvent<HTMLDivElement>, idx: number) => {
        if (navItems[idx].children)
            setMenuAnchor({ anchorEl: event.currentTarget, idx });
        else closeMenu();
    };

    const onClick = (path?: string) => {
        if (path) {
            navigate(path);
            closeMenu();
        }
    };

    return (
        <div className='header' onMouseLeave={closeMenu}>
            {navItems.map((navItem, idx) => {
                let className = 'header-button';

                if (isActive(navItem, activeNavItem))
                    className += ' active';
                else if (idx == menuAnchor?.idx)
                    className +=  ' hovered';

                return (
                    <div
                        key={`header-button-${navItem.title}`}
                        className='header-button-div'
                        onClick={() => onClick(navItem.path)}
                        onMouseEnter={(event) => openMenu(event, idx)}
                    >
                        <div className={className} data-testid={`nav-item-${navItem.title}`}>
                            {navItem.title}
                        </div>
                    </div>
                );
            })}
            <Menu
                anchorEl={menuAnchor?.anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={!!menuAnchor}
                onClose={closeMenu}
                slotProps={{ paper: { className: 'header-sub-menu' }}}
                onMouseLeave={closeMenu}
                data-testid='sub-nav-menu'
            >
                {menuAnchor && navItems[menuAnchor.idx].children?.map((navItem: NavItem) => {
                    let className = 'header-sub-menu-item';

                    if (isActive(navItem, activeNavItem))
                        className += ' active';

                    return (
                        <MenuItem
                            key={`menu-item-${navItem.title}`}
                            onClick={() => onClick(navItem.path)}
                            className={className}
                            data-testid={`sub-nav-item-${navItem.title}`}
                        >
                            {navItem.title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default Header;
