import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

import AgileDevelopment from './ServiceSummaries/AgileDevelopment';
import CyberSecurity from './ServiceSummaries/CyberSecurity';
import DataAnalytics from './ServiceSummaries/DataAnalytics';
import SystemModernization from './ServiceSummaries/SystemModernization';
import CloudMigration from './ServiceSummaries/CloudMigration';
import PageHeader from '../../common/PageHeader/PageHeader';


const serviceList = [
    {
        path: 'agile-development',
        label: 'Agile Development',
        content: <AgileDevelopment/>
    }, {
        path: 'system-modernization',
        label: 'System Modernization',
        content: <SystemModernization/>
    }, {
        path: 'data-analytics',
        label: 'Data Analytics & Visualization',
        content: <DataAnalytics/>
    }, {
        path: 'cloud-migration',
        label: 'Cloud Migration',
        content: <CloudMigration/>
    }, {
        path: 'cyber-security',
        label: 'Cyber Security',
        content: <CyberSecurity/>
    }
];

const title = 'Services';
const subtitle = 'Our team is devoted to researching and developing new and emerging technologies, allowing us to stay on top of the latest solutions for our clients.'

/**
 * TODO add sidebar w/ navigation, remove tabs
 * @returns 
 */
const Services: React.FC = () => {
    
    const navigate = useNavigate();
    const { option } = useParams();

    const [activeTab, setActiveTab] = React.useState<number>(0);

    React.useEffect(()=> {
        if (option) {
            const found = serviceList.findIndex(tab => tab.path == option);
            if (found != -1) {
                setActiveTab(found);
                return;
            }
        }

        navigate(`/services/${serviceList[0].path}`);
    }, [option]);
    
    const handleChange = (_event: React.SyntheticEvent, tabIdx: number) => {
        navigate(`/services/${serviceList[tabIdx].path}`);
    };

    return (
        <div className='services'>
            <PageHeader title={title} subtitle={subtitle} />
            <div className='page-content'>
                <Tabs 
                    className='tabs' 
                    value={activeTab}
                    onChange={handleChange} 
                    aria-label='Services Tabs'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                >
                    {serviceList.map((service, idx) => (
                        <Tab 
                            className='tab' 
                            key={`service-tab-${service.label}`} 
                            label={service.label}
                            value={idx}
                            wrapped
                        />
                    ))}
                </Tabs>
                {serviceList[activeTab].content}
            </div>
        </div>
    );
};

export default Services;