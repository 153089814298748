import { ActiveNavItem, NavItem } from '../../../@types';

/**
 * Get classname that indicates if the menu button is active or not
 * @param route 
 * @returns 
 */
export const isActive = (navItem: NavItem, activeNavItem: ActiveNavItem): boolean => {
    if (navItem.path) {
        return activeNavItem.main?.title == navItem.title;
    }
    
    if (navItem.children) {
        return activeNavItem.parent?.title == navItem.title;
    }

    return false;
};

/**
 * Get the list of all navigation menu items to map into header/sidebar/footer components.
 * 
 * Is a function in order to easily be mocked in tests.
 * 
 * @returns 
 */
export const getNavItems = (): NavItem[] => {
    return [
        { title: 'About', children: [
            { title: 'About Us', path: '/about' },
            { title: 'Partner With Us', path: '/partner' },
        ]},
        { title: 'Capabilities', children: [
            { title: 'Services', path: '/services' },
            { title: 'Products', path: '/products' },
            { title: 'Technology Innovations Lab', path: '/technology-innovations-lab' },
        ]},
        { title: 'Clients', children: [
            { title: 'Department of Homeland Security', path: '/clients/dhs' },
            { title: 'Department of Commerce', path: '/clients/doc' }
        ]},
        { title: 'Contracts', children: [
            { title: 'GSA MAS Schedule (Prime)', path: '/contracts/gsa-mas-schedule' },
            { title: 'GSA 8(a) STARS III', path: '/contracts/gsa-8a-stars-iii' },
        ]},
        { title: 'News', path: '/news' },
        { title: 'Connect', children: [
            { title: 'Careers', path: '/careers' },
            { title: 'Internship', path: '/careers/internship' },
            { title: 'Contact Us', path: '/contact' },
        ]},
    ];
};

export const updateActiveNavItem = (
    pathname: string,
    setActiveNavItem: (activeNavItem: ActiveNavItem) => void
) => {
    const navItems = getNavItems();

    if (['/', '/not-found'].includes(pathname)) {
        setActiveNavItem({ main: null, parent: null });
        return;
    }

    // check main app routes
    const found = navItems.find(route =>
        route.path ? pathname.startsWith(route.path) : false
    );
    if (found) {
        setActiveNavItem({ main: found, parent: null });
        return;
    }

    // check child app routes
    for (const route of navItems) {
        if (route.children) {
            const foundChild = route.children.find(child =>
                pathname.startsWith(child.path!)
            );
            if (foundChild) {
                setActiveNavItem({ main: foundChild, parent: route });
                break;
            }
        }
    }
}