import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import PageHeader from '../../common/PageHeader/PageHeader';
import { ReactComponent as TilGraphic } from '../../media/til/til-graphic.svg';

const TIL: React.FC = () => {

    const sectionTitle = 'We Invest in Innovation!'
    const p1 = 'SimonComputing\'s Technology Innovations Lab (TIL) drives our software development and analytical innovation by continuously researching new technologies and techniques to deliver new products and build high quality software faster. ';
    const p2 = 'Year after year, we significantly invest in the TIL through a rotating team of developers and other staff who identify and leverage the latest technologies to build libraries and productivity tools for our project teams. The team also evaluates dozens of industry products and tools yearly that are relevant to current and upcoming projects, such as development software, collaboration tools, mobile devices, and other hardware, keeping us involved with all of the latest innovations and trends.  The TIL additionally provides support for our technical challenges responses as well as research and innovation for our product lines.';
    
    return (
        <div className='til'>
            <PageHeader title='Technology Innovations Lab'/>
            <Grid className='content' container columnSpacing={10} rowSpacing={5}>
                <Grid className='grid-item-img' item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                    <TilGraphic />
                </Grid>
                <Grid className='text' item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                    <div className='section'>
                        <Typography className='section-title'>{sectionTitle}</Typography>
                        <Typography className='body'>{p1}</Typography>
                    </div>
                    <div className='section'>
                        <Typography className='body'>{p2}</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default TIL;