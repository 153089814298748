import * as React from 'react';

import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

interface DisplayProps {
    serviceId: string;
    title: string;
    summary: string | string [];
    graphic?: JSX.Element;
    additonalContent?: JSX.Element;
    layout?: 'text-left' | 'text-right'
}
/**
 * Service Display will display a service in grid format with the text on the left or right side (determined by the layout prop).
 * 
 * additonalContent prop: (optional) will be displayed in an expanable box below the text and graphic
 * 
 * summary prop: can be provided as a string or string []; if a string [] is provided, the summary will be formatted as a list
 * @param props serviceId (Unique Identifier), title, summary, graphic (optional), expanded content (optional), layout (default: text-left)
 * @returns 
 */
const ServiceDisplay: React.FC <DisplayProps> = props => {

    const { serviceId, title, summary, graphic, additonalContent, layout='text-left' } = props;

    //establish the layout for text and images when the screen size has changed
    //when the screensize is md and below, the image will always be displayed first
    const textOrder = layout == 'text-left'? {xs: 2, md: 1} : {xs: 2, md: 2};
    const imgOrder = layout == 'text-left'? {xs: 1, md: 2} : {xs: 1, md: 1};

    /**
     * If the summary is provided as a list, return a formatted list with bullets. If not, return the string
     * @returns string | JSX.Element
     */
    const getSummary = () => {
        if (Array.isArray(summary)){
            return (
                summary.map((value, idx) => (
                    <div className='list-item' key={`list-item-${idx}`}>
                        <div className='bullet-div'><CircleIcon className='bullet'/></div>
                        <div key={`summary-list-${idx}`}>{value}</div>
                    </div>
                ))
            );
        }
        return summary;
    };
    return (
        <Grid id={serviceId} className='service-display' container>
            <Grid item xs={12} md={6} order={textOrder}>
                <div className='text'>
                    <Typography className='section-title'>{title}</Typography>
                    <Typography className='section-summary'>{getSummary()}</Typography>
                </div>
                {additonalContent}
            </Grid>
            <Grid item xs={12} md={6} order={imgOrder}>
                <div className='illustration'>
                    {graphic}
                </div>
            </Grid>
        </Grid>
    );
};

export default ServiceDisplay;