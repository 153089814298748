import * as React from 'react';

import { Box, ButtonBase, Typography } from '@mui/material';

import bidenLetter from '../../../media/contracts/biden-letter.png'
import gsaMas from '../../../media/contracts/gsamas.png';

const GSAMAS: React.FC = () => {
    const pdfLink = 'https://www.whitehouse.gov/wp-content/uploads/2021/12/M-22-03.pdf';
    
    return (
        <div className='additional-content'>
            <Box
                className='img'
                component='img'
                src={gsaMas}
                alt='GSA MAS Schedule logo'
            />
            <Typography className='body'>
                The GSA Schedule, also known as Federal Supply Schedule, and Multiple Award Schedule (MAS), is a long-term governmentwide contract with commercial companies that provide access to millions of commercial products and services at fair and reasonable prices to the government. MAS makes buying easy and efficient with the use of modern technology to connect government buyers and industry.
            </Typography>
            <br/><br/>
            <Typography className='ct-title'> The President’s December 2021 Executive Order Calls for Increased Awards to WOSB, Small Disadvantaged Businesses</Typography>
            <ul className='body'>
                <li>Commitment to increase awards to small disadvantaged businesses including women-owned small businesses (WOSB)</li>
                <li>Targets 15% goal by FY 2025, with 11% in FY 2022 </li>
                <li>Interim goals are required to be reported by each Federal agency</li>
            </ul>
            <br/>
            <Typography className='body'>
                {'To learn more about executive order, check it out below or '}
                <a 
                    href={pdfLink} 
                    target='_blank' 
                    rel='noopener noreferrer'
                >
                    Click Here
                </a>
            </Typography>
            <div className='flex-row' style={{width: '100%', justifyContent: 'center'}}>
                <ButtonBase
                    style={{display: 'flex', width: 'fit-content'}}
                    target='_blank' 
                    rel='noopener noreferrer'
                    href={pdfLink}
                >
                    <Box
                        className='img large clickable'
                        component='img'
                        src={bidenLetter}
                        alt='Screenshot of President Biden&apos;s executive order'
                    />
                </ButtonBase>
            </div>
        </div>
    );
};

export default GSAMAS;