import * as React from 'react';

import { Typography, Grid } from '@mui/material';

import SEO from '../../common/SEO/SEO';
import MapContainer from './MapContainer';

const Contact: React.FC = () => {

    const title = 'Contact Us';
    const subtitle = 'We\'d love to hear from you!'

    const contactList = [
        {
            header: 'Address',
            lines: [
                '5350 Shawnee Road STE 200\nAlexandria, VA 22312'
            ]
        }, {
            header: 'Email',
            lines: [
                'General Info: info@simoncomputing.com',
                'Employment Info: careers@simoncomputing.com',
                'Contract Info: \nstars2@simoncomputing.com\nstars3@itellectsimonjv.com',
            ]
        }, {
            header: 'Phone',
            lines: [
                'Office: (703) 914-5454',
                'Fax: (703) 914-1133'
            ]
        },
    ];

    return (
        <div className='contact'>
            <SEO title='Contact'/>
            <Grid className='content' container >
                <Grid className='contact-overview' item xs={12} md={6}>
                    <div>
                        <Typography className='title'>{title}</Typography>
                        <Typography className='blurb'>{subtitle}</Typography>
                        {contactList.map((contact, idx) => (
                            <div className='contact-section' key={`contact-header-${idx}`}>
                                <div className='header-box'>
                                    <Typography className='contact-header'>{contact.header}</Typography>
                                </div>
                                <div className='contact-info'>
                                    {contact.lines.map((line, j) => (
                                        <div key={`contact-${contact.header}-line-${j}`}>
                                            <Typography className='contact-line' >{line}</Typography>
                                            {j < contact.lines.length - 1 && <br/>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>
                <Grid id='map' className='map' item xs={12} md={6}>
                    <MapContainer/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Contact;