import * as React from 'react';

import { Box, Typography } from '@mui/material';

import gsa8aiii from '../../../media/contracts/gsa8astarsiii.png'

const GSA8AIII: React.FC = () => {
    return (
        <div className='additional-content'>
            <Box
                className='img'
                component='img'
                src={gsa8aiii}
                alt='GSA 8(a) Stars III logo'
            />
            <Typography className='ct-title'>
                Contract #: 47QTCB21D0402
            </Typography>
            <Typography className='body'>
                8(a) STARS III is a small business set-aside Government Wide Acquisition Contract (GWAC),
                will provide flexible access to customized IT solutions from a large, diverse pool of 8(a) industry partners.
                This next-generation GWAC will build upon the framework of 8(a) STARS II and expand capabilities for emerging technologies and OCONUS requirements.
                ⁠— www.gsa.gov/s3
            </Typography>
            <Typography className='ct-title'> Features of STARS III: </Typography>
            <ul className='body'>
                <li>$50 billion program ceiling</li>
                <li>Five year base period plus one, three year option</li>
                <li>Task orders can extend 5 years beyond last ordering day</li>
                <li>Task order types: fixed price, labor hour, and time and material terms</li>
                <li>CONUS/OCONUS services</li>
                <li>Emerging Technology scope area</li>
                <li>Directed Order Authority: task orders up to $4 million each</li>
            </ul>
            <Typography className='ct-title'> Benefits of STARS III: </Typography >
            <ul className='body'>
                <li>Enables federal clients to earn 8(a) and Small Business credit</li>
                <li>Pre-competed, easy-to-use contracts</li>
                <li>Access to proven 8(a) small business technology providers</li>
                <li>Sub Pool of pre-selected 8(a) firms with deep Emerging Technology capabilities</li>
                <li>Short procurement lead time</li>
                <li>A low user access fee (0.75%) built into contractor’s ceiling price</li>
                <li>Limited protest ability in accordance with NDAA 2008</li>
                <li>Sole source awards up to $4.5 million ceiling</li>
            </ul>
            <Typography className='ct-title' >For More Information</Typography >
            <ul className='body' style={{ wordWrap: 'break-word' }}>
                <a href="https://www.itellectsimonjv.com">
                    ITellectSimon JV website<br />
                </a>
                <a href="https://www.gsa.gov/technology/technology-purchasing-programs/governmentwide-acquisition-contracts/8a-stars-iii">
                    GSA 8(a) STARS III website<br />
                </a>
            </ul>

        </div>
    );
};

export default GSA8AIII;