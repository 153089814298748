import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import SEO from '../../common/SEO/SEO';
import { ReactComponent as Icon404 } from '../../media/not-found/not-found.svg';
import StyledButton from '../../common/StyledButton/StyledButton';

interface Props {
    title?: string;
    path?: string;
    btnLabel?: string;
    notFound?: string;
}

/**
 * 
 * @param props 
 * @returns 
 */
const NotFound: React.FC<Props> = props => {

    const { 
        title='Not Found', 
        path='/', 
        btnLabel='Back to Home',
        notFound='Page'
    } = props;

    const { pathname } = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (pathname != '/not-found')
            navigate('/not-found', { replace: true });
    }, [pathname]);

    return (
        <div className='not-found'>
            <SEO title={title}/>
            <Icon404 width={300}/>
            <div className='text'>
                <Typography className='h1'>
                    {notFound} Not Found
                </Typography>
                <Typography className='h2'>
                    The {notFound.toLowerCase()} you are looking for does not exist
                </Typography>
            </div>
            <StyledButton 
                path={path}
                label={btnLabel}
                arrowPlacement='start'
            />
        </div>
    );
};

export default NotFound;
