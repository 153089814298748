import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import LogoList from '../../common/LogoList/LogoList';
import partnerGraphic from '../../media/partner-with-us/partner-with-us.png';
import PageHeader from '../../common/PageHeader/PageHeader';
import { partners } from '../../common/Utils/utils';
import StyledButton from '../../common/StyledButton/StyledButton';

const statementToPartners = 'Let\'s work together to redefine the possibilities in software development and forge lasting relationships built on trust.';

const p1 = '\tSimonComputing seeks partnerships with companies that bring specialized expertise in their field so that we can best meet our customer\'s needs.'
    + ' We\'re a Women Owned Small Business with over 20 years of experience developing mission critical software solutions for various government agencies'
    + ' including DHS, CBP/PSPD, CBP/BEMSD, CBP/CSD, CISA/OBP, USCIS, and DOC/BIS. We specialize in high volume System Modernization, Data Analytics and Visualization,'
    + ' Cloud Computing, AI/ML, and Emerging Technologies. We\'re committed to fostering collaborative teaming partnerships that build long term successful relationships.';


const PartnerWithUs: React.FC = () => {
    const title = 'Partner With Us';

    return (
        <div className='about'>
            <PageHeader title={title} />
            <div className='overview'>
                <Typography className='body'><p/></Typography>
                <div className='content'>
                    <Grid className='top' container columnSpacing={10}>
                        <Grid className='text-section' item xs={12} md={7} order={{xs: 2, md: 1}}>
                            <Typography className='body hand-written'>{statementToPartners}</Typography>
                            <Typography className='body'><p/></Typography>
                            <Typography className='body'>{p1}</Typography>
                            <Typography className='body'><p/></Typography>
                            <StyledButton label='Contact Us' path='/contact' />
                        </Grid>
                        <Grid className='graphic-section' item container xs={12} md={5} order={{xs: 1, md: 2}}>
                            <img src={partnerGraphic} alt='Partner with us graphic' className='about-graphic'/>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <LogoList list={partners} />
        </div>
    );
};

export default PartnerWithUs;