import * as React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';

import PageHeader from '../../common/PageHeader/PageHeader';
import craftPreview from '../../media/products/craft-laptop.png';
import xavierPreview from '../../media/products/xavier-iphone.png';
import { ReactComponent as CraftLogo } from '../../media/products/craft-logo.svg';
import StyledButton from '../../common/StyledButton/StyledButton';

const summary = 'Leveraging our long history of impactful consulting, thoughtful leadership, and software development for our many clients, SimonComputing has developed innovative product solutions using the latest technologies to creatively help organizations perform better and drive improved business results.  Contact us to get more information and to learn how our products can help make a difference for you!';
const productList = [
    {
        img: craftPreview,
        alt: 'Preview of CRAFT application',
        logo: <CraftLogo width='200'/>,
        summary: 'CRAFT is a clinical trials financial management application used to track and manage clinical study costs and performance throughout the study lifecycle. Developed from many years of analysis and experience, CRAFT identifies the most cost-effective way of applying personnel to the most productive efforts while avoiding financial losses in clinical trials.',
        href: 'https://dvl.craft.info.simoncomputing.com/',
        displayProps: {
            layout: 'img-right',
            bgColor: 'blue',
            imgSize: 'large',
            disableBtn: true
        }
    }, {
        img: xavierPreview,
        alt: 'Xavier',
        title: 'Xavier Travel Document Reader',
        summary: 'Turn your smart phone into a passport scanner with Xavier™ for iOS and Android devices. Developed by BlackShark Tech, Xavier™ is the simple way to accurately capture biographic data from a passport\'s MRZ for use in your mobile applications. SimonComputing is proud to be an authorized vendor of Xavier™.',
        href: 'https://blacksharktech.com/#products',
        displayProps: {
            layout: 'img-left',
            bgColor: 'red',
            imgSize: 'regular',
            disableBtn: false,
        }
    }
];

const Products: React.FC = () => {

    return (
        <div className='products'>
            <PageHeader title='Our Products'/>
            <div className='content'>
                {productList.map((product,idx)=> (
                    <div className={`product-bg ${product.displayProps.bgColor}`} key={`product-${product.href}`}>
                        <div className={`prd-content ${product.displayProps.layout}`}>
                            <motion.div 
                                className='info'
                                initial={{ opacity: 0, x: product.displayProps.layout == 'img-right' ? -100 : 100}}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1.5, delay: idx *.2 }}
                            >
                                {product.title &&
                                    <Typography className={`info-title ${product.displayProps.bgColor}`}>
                                        {product.title}
                                    </Typography>
                                }
                                {product.logo &&
                                    <div className='info-title'>{product.logo}</div>
                                }
                                <Typography className='info-summary '>{product.summary}</Typography>
                                <StyledButton
                                    color={product.title?.includes('Xavier') ? 'error' : 'primary'}
                                    label={product.displayProps.disableBtn ? 'Coming Soon' : 'Check It Out'}
                                    href={product.href}
                                    disabled={product.displayProps.disableBtn}
                                    variant='contained'
                                />
                            </motion.div>
                            <Box
                                src={product.img}
                                component='img'
                                className={`img ${product.displayProps.imgSize}`}
                                alt={product.alt}
                            />
                        </div>
                    </div>
                ))}
                <div className='blurb-bg'>
                    <Typography className='blurb'>{summary}</Typography>
                    <StyledButton label='Contact Us' path='/contact' />
                </div>
            </div>
            <p/> 
        </div>
    );
};

export default Products;