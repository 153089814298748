import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, IconButton, Link, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { FacebookSharp, LinkedIn } from '@mui/icons-material';

import { ReactComponent as SCLogo } from '../../../media/logos/simoncomputing/logo-solid.svg';
import { getNavItems } from '../Navigation/utils';

const socials = [
    { 
        icon: <FacebookSharp />, 
        link: 'https://www.facebook.com/SimonComputingInc/' 
    }, { 
        icon: <LinkedIn />, 
        link: 'https://www.linkedin.com/company/simoncomputing-inc-' 
    }
];

const today = new Date();
const copyright = `SimonComputing, Inc ${today.getFullYear()}`;

const Footer: React.FC = () => {

    const navigate = useNavigate();

    return (
        <div className='footer'>
            <div className='footer-content'>
                <div className='footer-content-left'>
                    <SCLogo className='logo' data-testid='sc-logo' />
                    <div
                        className='footer-map'
                        data-testid='footer-map'
                        onClick={() => navigate('/contact')}
                    />
                    <div className='social-icons' data-testid='social-icons'>
                        {socials.map((social) => (
                            <IconButton key={`social-${social.link}`} component={Link} href={social.link}>
                                {React.cloneElement(social.icon, { className: 'social-icon' })}
                            </IconButton>
                        ))}
                    </div>
                </div>
                <div className='footer-content-right'>
                    <div className='footer-navigation'>
                        {getNavItems().map((route) => 
                            <div
                                className='nav-group'
                                key={`route-${route.title}`}
                                data-testid='nav-group'
                            >
                                <div className='nav-group-header'>
                                    <Typography
                                        className='category-title'
                                        data-testid='category-title'
                                        onClick={() => {
                                            if (route.path) navigate(route.path)
                                        }}
                                        style={route.children ? undefined : { cursor: 'pointer' }}
                                    >
                                        {route.title}
                                    </Typography>
                                    <Divider className='underline'/>
                                </div>
                                <List>
                                    {route.children?.map((child) => (
                                        <ListItemButton
                                            className='route-list-item'
                                            data-testid='route-list-item'
                                            key={`route-${route.title}-child-${child.title}`} 
                                            onClick={() => {
                                                navigate(child.path!)
                                            }}
                                        >
                                            <ListItemText className='route-title'>
                                                {child.title}
                                            </ListItemText>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </div>
                        )}
                    </div>
                </div>
                <div className='footer-content-bottom'>
                    <Typography className='footer-text'>&copy; {copyright}</Typography>
                </div>
            </div>
        </div>
    );
};

export default Footer;