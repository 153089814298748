import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as SimonComputingLogo } from '../../../media/logos/simoncomputing/byline-gradient.svg';
import Header from './Header';
import Sidebar from './Sidebar';
import { updateActiveNavItem } from './utils';
import { ActiveNavItem } from '../../../@types';

const Navigation: React.FC = () => {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const [activeNavItem, setActiveNavItem] = React.useState<ActiveNavItem>({ main: null, parent: null });

    React.useEffect(() => {
        // scroll to top of page
        window.scrollTo(0, 0);
        updateActiveNavItem(pathname, setActiveNavItem);
    }, [pathname]);

    const mobile = useMediaQuery(useTheme().breakpoints.down('md'));
    
    return(
        <AppBar
            className='navigation app-bar'
            elevation={0}
            data-testid='nav-app-bar'
        >
            <Toolbar className='toolbar'>
                <div className='app-logo' onClick={() => navigate('/')} data-testid='logo'>
                    <SimonComputingLogo />
                </div>
                {mobile
                    ? <Sidebar activeNavItem={activeNavItem} />
                    : <Header activeNavItem={activeNavItem} />
                }
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
