export interface ClientPageContent {
    title: string;
    content: {
        title: string;
        subtitle: string;
        list: string[];
    }[];
    quote: {
        text: string;
        name: string;
        dept: string;
    }
}

export const docContent: ClientPageContent = {
    title: 'Department of Commerce',
    content: [
        {
            title: 'Digital Services Technical Architecture Support',
            subtitle: 'Department of Commerce (DOC), Bureau of Industry and Security (BIS)',
            list: [
                'Provide digital service teams to modernize BIS mission applications and technical architecture to enhance national security.',
                'Architect and implement BIS\'s data ecosystem which directs multiple data streams to a central repository and creates insights that were not possible in the previously siloed environment.',
                'Enable comprehensive security using Microsoft Purview to ensure proper management of sensitive information across the entire Office 365 environment',
            ]
        }
    ],
    quote: {
        text: 'SimonComputing is, hands down, one of the best small business government IT contractors that I have had the pleasure of working with.',
        name: '',
        dept: 'Contracting Officer Representative (COR), DOC BIS'
    }
};

const CBP_DHS = 'U.S. Customs and Border Protection (CBP/DHS)';

export const dhsContent: ClientPageContent = {
    title: 'Department of Homeland Security',
    content: [
        {
            title: 'Digital Services Solution Minimum Viable Product (MVP) Agile Software Development for Data Visualization Tool',
            subtitle: 'Office for Bombing Preventing (OBP/CISA/DHS)',
            list: [
                'Automating the capture of data from multiple sources into a single data repository',
                'Providing advanced analytic capabilities, including dashboards with summaries, alerts, 3D displays, and animation to depict the flow of actual data',
            ]
        },{
            title: 'Mobility Center of Excellence and Expertise (MCEE)',
            subtitle: CBP_DHS,
            list: [
                'CBP ROAM (Reporting Offsite Arrival - Mobile)',
                'I94/Exit',
                'Agriculture Inspection (AI-288)',
            ]
        }, {
            title: 'Passenger Systems Program Directorate (PSPD)',
            subtitle: CBP_DHS,
            list: [
                'TECS Modernization',
                'Western Hemishpere Travel Initiative (WHTI)',
                'Decal and Transponder Online Procurement Syste(DTOPS)',
                'e-Passport',
                'Electronic System for Travel Authorization (ESTA)',
                'Global Online Enrollment System (GOES)',
                'Travel Primary Arrival Client (TPAC)',
                'Pre Departure Service (PDS)'
            ]
        }, {
            title: 'Border Enforcement Management Systems Division (BEMSD)',
            subtitle: CBP_DHS,
            list: [
                'Modernization of Seized Assets and Case Tracking System (SEACATS)',
                'Firearms, Armor, and Credentials Tracking System (FACTS)',
                'E3',
            ]
        }, {
            title: 'U.S. Immigration and Customs Enforcement (ICE)',
            subtitle: CBP_DHS,
            list: [
                'Student and Exchange Visitor Information Systems (SEVIS II)',
                'Blanket Purchase Agreement (BPA) for Software Operations and Maintenance (O&M)',
            ]
        }, {
            title: 'CBP Benefits, Medical and Worklife (BM&W)',
            subtitle: CBP_DHS,
            list: [
                'Drug Testing Program',
            ]
        }, {
            title: 'Transport Security Administration (TSA)',
            subtitle: 'Transport Security Administration (TSA)',
            list: [
                'Transportation Threat Assessment and Credentialing (TTAC)',
            ]
        }
    ],
    quote: {
        text: 'SimonComputing has been an integral part of many high profile CBP and DHS systems over the years'
            + ' including US-VISIT, TECS Modernization, WHTI, ESTA and many more. They can be counted on to deliver'
            + ' a great product on time. They are very responsive to the customer and really form a contractor/government'
            + ' partnership to get the job done. Even before Agile became a popular methodology, SimonComputing used many'
            + ' of the Agile concepts – break things down, deliver quickly and build upon it. They have been a big part'
            + ' of PSPD shift from Waterfall to Agile and have made it a success. The projects that SimonComputing supports'
            + ' have been our projects to lead the way with Agile methodology, processes and tools. They have successfully'
            + ' adopted Agile processes and tools to benefit our projects.  I highly recommend SimonComputing.',
        name: '– Executive Director PSPD',
        dept: 'CBP, DHS'
    }
}