import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';

import { Post } from '../../@types';
import PostCard from './PostCard/PostCard';
import StyledButton from '../../common/StyledButton/StyledButton';

interface Props {
    posts: Post[];
    title: string;
    mode?: 'light' | 'dark';
    button?: { title: string; to: string; };
    handleTagClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, val: string) => void;
    blurb?: boolean;
}

/**
 * 
 * @param props 
 * 
 * posts: list of featured posts
 * title: section title
 * mode: color mode
 * button: button content
 * handleTagClick: tag click handler
 * blurb: if blurb should be displayed on cards
 * 
 * @returns 
 */
const FeaturedPosts: React.FC<Props> = props => {

    const { 
        posts, 
        title, 
        mode='dark', 
        button,
        handleTagClick,
        blurb=false,
    } = props;

    return (
        <div className={`featured-posts ${mode}`}>
            <div className='fp-container'>
                <Typography className='fp-title'>{title}</Typography>
                <Grid className='fp-posts-container' container spacing={3}>
                    {posts.map((post, idx) => 
                        <Grid className='fp-post-item' 
                            key={`post-${post.id}`} 
                            item xs={12} sm={6} md={4}
                        >
                            <motion.div className='fp-post-motion-div'
                                initial={{ opacity: 0 , y: 50}}
                                whileInView={{ opacity: 1 , y: 0}}
                                transition={{ duration: 1, delay: (idx + 1) * .25 }}
                                viewport={{ once: true }}
                            >
                                <PostCard 
                                    post={post} 
                                    elevated 
                                    blurb={blurb}
                                    handleTagClick={handleTagClick}
                                />
                            </motion.div>
                        </Grid>
                    )}
                </Grid>
                {button && 
                    <div className='fp-btn-div'>
                        <StyledButton label={button.title} path={button.to} />
                    </div>
                }
            </div>
        </div>
    );
};

export default FeaturedPosts;